import Header from '../header';
import Layout from '../../../components/Layout/admin';
import classes from './styles.module.scss';
import { HiChevronDown } from 'react-icons/hi';
import { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../context/provider';
import { getAdminStores } from '../../../context/actions/admin/store';
import { GET_ADMIN_STORES } from '../../../context/actions/types';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';

interface Admin {
  id: number;
  name: string;
  type: string;
  status: string;
  createdAt: string;
}
const Stores = () => {
  const { adminStoreState, adminStoreDispatch } =
    useContext<any>(GlobalContext);
  const [stores, setStores] = useState<Admin[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchAdminStores();
  }, [])

  useEffect(() => {
    if (adminStoreState?.adminStores) {
      const stores = adminStoreState?.adminStores;
      setStores(stores);
      adminStoreDispatch({ type: GET_ADMIN_STORES, payload: null });
    }
    if (adminStoreState?.getAdminStoresError) {
      setIsErrorOpen(true);
      setErrorMessage(
        adminStoreState?.getAdminStoresError
      );
    }
  }, [adminStoreState, adminStoreDispatch]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    });
  };

  const fetchAdminStores = async () => {
    setLoading(true)
    await getAdminStores()(adminStoreDispatch);
    setLoading(false)
  };

  return (
    <Layout>
      <ErrorToast
      message={errorMessage}
      type={'error'}
      isOpen={isErrorOpen}
      onClose={()=>{
        setIsErrorOpen(false)
      }}
      />
      <div className={classes.header}>
        <Header title="Stores" />
      </div>
      <div className={classes.table}>
        <table>
          <thead>
          <tr>
            <th className={classes.id}> ID</th>
            <th className={classes.name}>
            <div className={classes.drop_down}>
              <HiChevronDown />
             <p>Store Name</p>
             </div>
            </th>
            <th className={classes.type}>Category</th>
            <th>Status</th>
            <th className={classes.created}>Created On</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {stores.map((store) => {
            return (
              <tr key={store.id}>
                <td className={classes.input_sec}>
                  <input type="checkbox" />
                  {store.id}
                </td>
                <td className={classes.name}>{store.name}</td>
                <td className={classes.type}>{store.type}</td>
                <td  style={{fontWeight:600 ,textTransform: 'capitalize', color: store.status === 'active'? '#3EB83D': store.status === 'pending'? '#F6D35F': store.status === 'deactivated'? '#E53A34': 'black'}}>{store.status}</td>
                <td>{formatDate(store.createdAt)}</td>
                <td>
                   <Link to={`/admin/dashboard/stores/${store.id}`}>
                    <button className={classes.view}>View</button>
                  </Link>
                </td>
              </tr>
            );
          })}
          </tbody>
          <tfoot>
            <tr className={classes.footer} >
              Showing: {stores.length} stores
            </tr>
          </tfoot>
        </table>
        { loading &&
           <div className={classes.spinner}>
            <Spinner/>
            </div>
            }
      </div>
    </Layout>
  );

}

export default Stores;
