import { IonRippleEffect, IonIcon } from '@ionic/react';
import { searchSharp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import InputField from '../../../components/inputField';
import { clear } from 'console';

const SearchFieldLocal = ({
  setSearchText,
  text,
}: {
  setSearchText: (params: any) => void;
  text: string;
}) => {
  const [search, setSearch] = useState<{ query: string }>({ query: '' });
  useEffect(() => {
    setSearchText(search.query);
  }, [search]);
  return (
    <div
      className={
        'size-full relative flex flex-row items-center justify-center p-1'
      }
    >
      <InputField
        name="query"
        type="search"
        value={setSearch}
        clear
        placeholder="Search Products"
        inputType="text-field"
        required
      />
    </div>
  );
};

export default SearchFieldLocal;
