import { IonButton, IonIcon } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import StoreMenu from '../../../storeMenu/storeMenu';
import classes from './styles.module.scss';
import { isLoggedIn } from '../../../../utils/isLoggedIn';
import Menu from '../../../storeMenu/menu';
import { User } from '../../../../types/user';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';
import { menuOutline } from 'ionicons/icons';
import { menuController } from '@ionic/core/components';
import { hideMenuPaths } from '../../../../utils';
const Mobile = ({
  user,
  backgroundColor = 'white',
}: {
  user: User;
  backgroundColor?: 'primary' | 'white' |'transparent';
}) => {
  const history = useHistory();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isloggedIn = isLoggedIn();
  const [openDropDown, setOpenDropDown] = useState<{
    isOpen: boolean;
    event: Event | null;
  }>({
    isOpen: false,
    event: null,
  });

  useEffect(() => {
    if (openMenu && hideMenuPaths.includes(location.pathname)) {
      menuController.close('menu');
    }
  }, [location.pathname, openMenu]);

  const openCntx = (e: any) => {
    setOpenDropDown({ isOpen: !openDropDown.isOpen, event: e });
  };
  return (
    <div className={classes.mobile}>
      {isloggedIn && (
        <Menu
          open={openDropDown.isOpen}
          close={openCntx}
          userDetails={user}
          openEvent={openDropDown.event!}
        />
      )}
      <div
        style={{
          background: backgroundColor
            ? `var(--ion-color-${backgroundColor})`
            : 'var(--ion-color-primary})',
        }}
        className={classes.holder}
      >
        <div
          className={classes.logo}
          onClick={() => {
            history.push('');
          }}
        >
          <IonIcon src={logo}></IonIcon>
          <span
            className={classes.span1}
            style={{
              color:
                backgroundColor === 'white'
                  ? 'var(--ion-color-primary)'
                  : 'var(--ion-color-primary-contrast)',
            }}
          >
            Bazaar
          </span>{' '}
          <span className={classes.span2}>Cheap</span>
        </div>

        {isloggedIn ? (
          <div className={classes.chip}>
            <StoreMenu
              userDetails={user}
              open={openDropDown.isOpen}
              openCntx={openCntx}
            />
          </div>
        ) : (
          <div className={classes.btnGrp}>
            <IonButton
              routerLink={
                location.pathname === '/login' ? '/welcome' : '/login'
              }
              color="secondary"
              fill="clear"
              className={classes.btn}
            >
              {location.pathname === '/login' ? 'SIGN UP' : 'Log In'}
            </IonButton>
          </div>
        )}

        <div
          className={classes.menu}
          onClick={async () => {
            await menuController.open('menu');
            setOpenMenu(await menuController.isOpen('menu'));
          }}
        >
          <IonIcon
            icon={menuOutline}
            color={backgroundColor === 'primary' ? 'light' : 'primary'}
          ></IonIcon>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
