import { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  useIonActionSheet,
  IonRippleEffect,
} from '@ionic/react';
import ProductMetrics from './metricsCard';
import ItemDetailsCard from '../../../components/productDetailsCard';
import { useHistory, useLocation } from 'react-router';
import useGlobalContext from '../../../context/provider';
import {
  deleteProducts,
  getSingleProduct,
} from '../../../context/actions/products';
import { GET_SINGLE_PRODUCT_ERROR } from '../../../context/actions/types';
import { ProductType } from '../../../types/product';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import product from '../../../context/reducers/product';
import ProductCard from '../../user/product-details/productCard';
import { ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';
import { data } from '../notifications/dummy';

const ProductSeller = () => {
  const size = useContext(DeviceContext);
  const location = useLocation();
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<ProductType>({} as ProductType);
  const { productState, productDispatch } = useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const history = useHistory();
  const [present] = useIonActionSheet();
  const status = [
    {
      message: 'pending',
      status: 'pending',
      colors: { text: '#D68800', background: '#FFE9D2' },
    },
    {
      message: 'published',
      status: 'published',
      colors: { background: '#9EBFF8', text: '#0E6FD4' },
    },
    {
      message: 'approved',
      status: 'approved',
      colors: { text: '#28A745', background: '#BCEFC8' },
    },
    {
      message: 'rejected',
      status: 'rejected',
      colors: { text: '#CC292A', background: '#F09CA4' },
    },
  ];
  const options = [
    {
      name: 'Edit',
      action: () => {
        setOpenMenu(!openMenu);
        history.push({
          pathname: `/dashboard/edit-product/detail?q=${id}&i=${item.name.slice(
            0,
            30
          )}`,
          state: { id },
        });
        destroy();
      },
    },
    {
      name: 'Delete',
      action: () => {
        setOpenMenu(!openMenu);
        presentDeletePrompt();
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    const _id2 = location.pathname
      .split('?')
      .slice(-1)[0]
      .split('&')[0]
      .split('=')
      .slice(-1)[0];
    const _id: any = location.state ? location.state : null;
    if (_id) {
      setId(`${_id.id}`);
      fetchItem(_id.id);
    } else if (_id2) {
      setId(_id2);
      fetchItem(Number(_id2));
    }
  }, []);

  useEffect(() => {
    if (productState.getSingleProduct) {
      const product = productState.getSingleProduct;
      if (product.id === Number(id)) {
        setItem(product);
        setLoading(false);
      }
    }
    if (productState?.getSingleProductError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState?.getSingleProductError!?.response!?.data!?.message
          ? productState?.getSingleProductError!?.response!?.data!?.message
          : productState?.getSingleProductError!?.message
      );
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
      setLoading(false);
    }
  }, [productState?.getSingleProduct]);

  useEffect(() => {
    if (item.id) setLoading(false);
  }, [item]);

  async function fetchItem(id: number) {
    if (productState?.getSingleProduct?.id === id) {
      const product = productState.getSingleProduct;
      setItem(product);
      return;
    }
    await getSingleProduct(id)(productDispatch);
  }
  const destroy = () => {
    setItem({} as ProductType);
    setId('');
  };
  async function deletedata(id: string) {
    await deleteProducts({ id })(productDispatch);
  }
  function presentDeletePrompt() {
    present({
      header: 'Are You Sure You Want To Delete This Product?',
      subHeader: 'This action cannot be reversed.',
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            deletedata(`${item.id}`);
          },
          data: {
            action: 'delete',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
      cssClass: 'deletePrompt',
      backdropDismiss: true,
    });
  }
  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div id="pageContainer" className={classes.pageContainer} key={id}>
        {loading ? (
          <div className={classes.loading}>
            <Spinner type="crescent"></Spinner>
          </div>
        ) : (
          <IonGrid className={'size-full p-0 m-0 bg-transparent'}>
            <IonRow className={classes.container + ' size-full relative flex'}>
              <IonCol
                className={' relative size-full px-[10dvh] overflow-y-scroll'}
                size={'12'}
              >
                <div className={' py-2 size-full'}>
                  <div className="w-full h-auto flex flex-row items-center justify-end">
                    <IonButton
                      fill="clear"
                      color="primary"
                      onClick={() => {
                        setOpenMenu(true);
                      }}
                      style={{
                        '--color': 'var(--ion-color-primary)',
                        '--padding-start': '0.8em',
                        '--padding-end': '0.8em',
                      }}
                      size="default"
                    >
                      <IonIcon
                        slot="icon-only"
                        size="small"
                        icon={ellipsisVertical}
                      ></IonIcon>
                    </IonButton>
                  </div>
                  {openMenu && (
                    <div
                      className={`focus:outline-none focus:shadow-none before:fixed before:z-10 before:top-0 before:right-0 before:bottom-0 before:left-0 before:block before:cursor-pointer before:bg-[rgba(0,0,0,0.1)] before:content-[" "] before:col-span-1`}
                      onClick={() => {
                        setOpenMenu(false);
                      }}
                    >
                      <div
                        className={
                          'w-auto min-w-[35dvw] md:min-w-[10dvw] h-fit absolute z-20 bg-white shadow-sm rounded-lg right-[8%] px-4 py-2'
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <ul>
                          {options.map((item, index) => {
                            return (
                              <div
                                className={
                                  ' hover:bg-gray-50 ion-activatable ripple-parent circle py-2 text-[var(--ion-color-primary)] text-lg font-["Roboto", sans-serif] font-base cursor-pointer'
                                }
                                key={index}
                                onClick={() => {
                                  item.action();
                                }}
                              >
                                <IonRippleEffect></IonRippleEffect>
                                {item.name}
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  <ProductCard
                    data={item}
                    disableWishlist={true}
                    isVendor={true}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </div>
    </Layout>
  );
};

export default ProductSeller;
