import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useContext } from 'react';
import Layout from '../../../components/Layout/user/Layout';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import ContactUsForm from '../../../components/contactusForm/contact';

const AboutUs = () => {
  const device = useContext(DeviceContext);

  return (
    <Layout>
      <IonGrid fixed={true}>
        <div className={classes['top-section']}>
          <IonRow
            className={`${classes['title-row']} ${
              device.width < 460 && classes['sm-title-padding']
            }`}
          >
            <IonCol size="12">
              <h1
                className={`${classes.title} ${
                  device.width < 460 && classes['sm-title']
                }`}
              >
                Bazaar <span>Cheap</span>
              </h1>
              {device.width > 769 && (
                <img
                  className={`${classes.image} ${
                    device.width >= 1080 &&
                    device.width <= 1150 &&
                    classes['md-img']
                  }  ${device.width > 1150 && classes['lg-img']}`}
                  src="assets/bazaar-home.png"
                  alt=""
                />
              )}
            </IonCol>
          </IonRow>

          <IonRow
            className={`${classes['intro-row']} ${
              device.width < 460 && classes['sm-title-padding']
            }`}
          >
            <IonCol size="12" size-md="9" className={classes.about}>
              <p>
                Bazaar Cheap is an online window shopping platform that connects
                buyers and sellers across Uganda. Our platform is designed to
                provide a seamless shopping experience for customers while
                allowing sellers to reach a wider audience.
              </p>
              <p>
                We understand that shopping can be time-consuming and
                frustrating, especially when you have to visit multiple stores
                to find what you need. With Bazaar Cheap, you can browse
                products from various sellers in one place, saving you time and
                effort.
              </p>
            </IonCol>
          </IonRow>
        </div>

        <IonRow
          className={`${classes['feature-row']} ${
            device.width < 460 && classes['sm-padding']
          }`}
        >
          <IonCol size="12" size-md="6">
            <div className={classes['feature-title-wrapper']}>
              <div className={classes.box}></div>
              <h6>Search Products</h6>
            </div>
            <p className={classes['feature-info']}>
              Our platform allows you to search for products based on category,
              price range, and location. You can also view seller profiles to
              learn more about their products and services. We strive to make
              your shopping experience as convenient and enjoyable as possible.
            </p>
          </IonCol>
          <IonCol size="12" size-md="6">
            <div className={classes['feature-title-wrapper']}>
              <div className={classes.box}></div>
              <h6>Seller Dashboard</h6>
            </div>
            <p className={classes['feature-info']}>
              Our platform allows you to search for products based on category,
              price range, and location. You can also view seller profiles to
              learn more about their products and services. We strive to make
              your shopping experience as convenient and enjoyable as possible.
            </p>
          </IonCol>
          <IonCol size="12" size-md="6">
            <div className={classes['feature-title-wrapper']}>
              <div className={classes.box}></div>
              <h6>Wishlist </h6>
            </div>
            <p className={classes['feature-info']}>
              Our platform empowers you to curate and organize your own
              personalized collection of items that catch your eye. Simply click
              the whishlist icon and add it to your collection for future
              reference.
            </p>
          </IonCol>
        </IonRow>

        <IonRow
          className={`${classes['contact-us-row']} ${
            device.width < 460 && classes['sm-padding']
          }`}
        >
          <IonCol size="12">
            <h4>Contact Us</h4>
          </IonCol>
          <IonCol size="12" size-md="6">
            <ContactUsForm />
          </IonCol>
          <IonCol size="12" size-md="6">
            <p>
              For any assistance, please reach out to our support team through
              the following numbers. We're here to help you with any inquiries
              or concerns you may have.
            </p>
            <p>
              <strong>Call: </strong>
              <a href="tel:+256784331902">+256 784 331 902</a>
            </p>
            <p>
              <strong>WhatsApp: </strong>
              <a
                href="https://wa.me/256784331902"
                target="_blank"
                rel="noopener noreferrer"
              >
                +256 784 331 902
              </a>
            </p>
            <p>
              <strong>Email: </strong>
              <a href="mailto:support@bazaarcheap.com">support@bazaarcheap.com</a>
            </p>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Layout>
  );
};

export default AboutUs;
