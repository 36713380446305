import { IoChevronBackSharp } from 'react-icons/io5';
import {  useIonRouter } from '@ionic/react';

type PageHeaderProps = {
  onBack?: () => void;
  label: string;
  styles?: { wrapper?: string; label?: string };
};

const PageHeader = ({ onBack, label, styles = {} }: PageHeaderProps) => {
  const router = useIonRouter();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      router.goBack();
    }
  };

  return (
    <div className={`w-full flex gap-2 items-center justify-between md:justify-start my-2 ${styles.wrapper}` }>
      <IoChevronBackSharp
        onClick={handleBack}
        className="text-xl cursor-pointer "
      />
      <span className={`text-lg text-primary font-semibold mx-auto lg:mx-0 ${styles.label}`}>
        {label}
      </span>
    </div>
  );
};

export default PageHeader;
