import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React, { useState } from 'react';
import classes from './styles/desktop.module.scss';
import { mailOutline } from 'ionicons/icons';
import {
  AboutRoutes,
  JoinRoutes,
  Socials,
} from '../../../../utils/user/routes';
import { Link } from 'react-router-dom';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';
const Desktop = () => {
  const [text, setText] = useState<string>();
  const email = 'support@bazaarcheap.com';

  return (
    <div className={classes.Container}>
      <IonGrid className={classes.holder}>
        <IonRow className={classes.content}>
          <IonCol size="3.75" className={classes.info}>
            <div className={classes.logo}>
              <IonIcon src={logo}></IonIcon>
              <span className={classes.span1}>Bazaar</span>{' '}
              <span className={classes.span2}>Cheap</span>
            </div>
            <p>
              Your ultimate destination for window shopping with a collection of
              products from top brands in all categories
            </p>
            <h2>Contact us</h2>
            <p>{email}</p>
          </IonCol>
          <IonCol size="3.75" className={classes.info}>
            <div className={classes.icons}>
              <IonLabel slot="start" color="secondary" className={classes.head}>
                Follow Us On
              </IonLabel>
              <ul className={classes.list} slot="end">
                {Socials.map((item, index) => {
                  return (
                    <IonButton
                      href={item.route}
                      target="_blank"
                      size="large"
                      fill="clear"
                      key={index}
                      className={classes.btn}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={item.icon}
                        color="light"
                        size="large"
                      />
                    </IonButton>
                  );
                })}
              </ul>
            </div>
          </IonCol>
          <IonCol size="2" className={classes.about}>
            <h2>About Bazaar Cheap</h2>
            <div className={classes.aboutHolder}>
              <ul>
                {AboutRoutes.map((item, index) => {
                  return (
                    <Link
                      to={item.route}
                      className={classes.aboutItem}
                      key={index}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </ul>
            </div>
          </IonCol>
          {/* <IonCol size="2" className={classes.join}>
            <h2>Join Our Society</h2>
            <div className={classes.joinHolder}>
              <ul>
                {JoinRoutes.map((item, index) => {
                  return (l
                    <li className={classes.joinItem} key={index}>
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </IonCol> */}
          {/* <IonCol size="3.75" className={classes.newsletter}>
            <h2>Bazaar Cheap Newsletter</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
              dolorem natus asperiores at temporibus dolores.
            </p>
            <IonItem className={classes.input}>
              <IonInput
                value={text}
                color="primary"
                type="email"
                className={classes.field}
                onIonChange={(e) => setText(e.detail.value!)}
              >
                <IonIcon
                  slot="start"
                  icon={mailOutline}
                  color="secondary"
                  className={classes.icon}
                ></IonIcon>
              </IonInput>
              <IonButton
                color="secondary"
                type="submit"
                size="default"
                onClick={(e) => {
                  // console.log(e)
                }}
              >
                Subscribe
              </IonButton>
            </IonItem>
          </IonCol> */}
        </IonRow>
        <IonRow className={classes.Copyright}>
          Copyright &copy; Bazaar Cheap {new Date().getFullYear()} | All Rights
          Reserved
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Desktop;
