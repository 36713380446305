import { IonCol, IonRow } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DeviceContext } from '../../../deviceType';
import Btn from '../../../components/Btn';
import InputCard from '../components/inputCard';
import classes from './styles.module.scss';
import AuthLayout from '../components/AuthLayout';
import { GlobalContext } from '../../../context/provider';
import { getUserInfo, login } from '../../../context/actions/user';
import { LOGIN } from '../../../context/actions/types';
import { eye } from 'ionicons/icons';
import { addToLocalStorage } from '../../../utils';
import { DecodedToken, authService } from '../../../utils/authService';

interface LoginForm {
  email: string;
  password: string;
}

const loginFields = [
  {
    title: 'Email',
    name: 'email',
    type: 'email',
  },
  {
    title: 'Password',
    name: 'password',
    type: 'password',
    maxLength: 16,
    minLength: 8,
    icon: eye,
  },
];
const redirect = localStorage.getItem('locationReferrer');

const Login: React.FC = () => {
  const device = useContext(DeviceContext);
  const history = useHistory();
  const [form, setForm] = useState<LoginForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [active, setActive] = useState<boolean | undefined>(false);
  const { userState, userDispatch } = useContext<any>(GlobalContext);
  const [error, setError] = useState<{
    state: boolean;
    message: string | null;
  }>({ state: false, message: null });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const user = {
      email: form?.email!,
      password: form?.password!,
    };
    await login(user)(userDispatch);
    setLoading(false);
  };

  useEffect(() => {
    const getLoggedInUserDetails = async (id: number) => {
      await getUserInfo(id)(userDispatch);
    };
    if (userState.logIn) {
      userDispatch({ type: LOGIN, payload: null });
      const userDetails = authService.getCurrentUser() as DecodedToken;
      getLoggedInUserDetails(userDetails?.id);
      resetform();

      if (userDetails.role === 'admin') {
        return history.push('/admin/dashboard/home');
      } else if (userDetails.role === 'user') {
        return history.push('/stores');
      } else if (userDetails.stores?.length) {
        addToLocalStorage({
          key: 'currentStore',
          value: userDetails.stores[0]?.id.toString(),
        });
        return history.push(`/dashboard/home`);
      } else {
        return history.push('/dashboard/create-store');
      }
    }

    if (userState.logInError) {
      setError({
        state: true,
        message: userState.logInError!?.response!?.data!?.message
          ? userState.logInError!?.response!?.data!?.message
          : userState.logInError!?.message,
      });
      userDispatch({ type: LOGIN, payload: null }); // this clears the api error
    }
  }, [userState]);

  useEffect(() => {
    if (form?.email?.trim()?.length) {
      setActive(true);
    }
  }, [form?.email]);

  function resetform() {
    setForm({ email: '', password: '' });
  }

  return (
    <AuthLayout>
      <div
        className={`${classes['login-page']} ${
          device.width > 600 && classes['wide-login-page']
        }`}
      >
        <div className={classes['title-wrapper']}>
          <h1 className={classes.title}>Login to Bazaar Cheap</h1>
        </div>
        <div className={classes['form-wrapper']}>
          <form onSubmit={handleSubmit}>
            {error?.state && (
              <IonRow className={classes.error}>
                <IonCol size="12">
                  <div>{error.message}.</div>
                </IonCol>
              </IonRow>
            )}

            <IonRow className={classes.lfields}>
              {loginFields.map((item, index) => (
                <IonCol size="12" key={index} className={classes['field-col']}>
                  <InputCard
                    title={item.title}
                    setForm={setForm}
                    name={item.name}
                    type={item.type}
                    icon={item.icon}
                  />
                </IonCol>
              ))}
            </IonRow>

            <IonRow className={classes.forgot}>
              <IonCol size="12" className={classes['forgot-col']}>
                <Link to="forgotpassword">Forgot Password?</Link>
              </IonCol>
            </IonRow>
            <IonRow className={classes['submit-btn-row']}>
              <IonCol size="12">
                <Btn
                  onClick={(e) => e}
                  match={active}
                  name="Login"
                  loading={loading}
                />
              </IonCol>
            </IonRow>

            <IonRow className={classes['signup-row']}>
              <IonCol size="12" className={classes['signup-col']}>
                <p>
                  Don't have an account? <Link to="signup">Sign up</Link>
                </p>
              </IonCol>
            </IonRow>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
