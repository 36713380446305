import { FaqsType } from '../types/faqs';

export const faqs: FaqsType[] = [
    {
        title: 'How to create a shop',
        content: ['1.Go to home, create a shop',
        '2.Register if you have not account if you have an account you will be redirected to create a Shop form'
        ]
    },
    {
        title: 'How to add products to your shop',
        content: ['1.Go to home of your Shop','2.Click \'CREATE\' button','3. Select either \'bulk product\' or \'single product\'',
             '4. Enter title for your product and provide additional details', '5. Click \'Save\''
    ]
    },
    // {
    //     title: 'How to add multiple products',
    //     content: ['1.Click \'CREATE\' ', '2.Select \'Bulk Products\'', '3.Download the sample csv file and add products following the sample data',
    //     '4.Replace the sample data with your own products or remove it.', '5.Save the file as a csv.', '6.Upload your file or drag and drop it in the top-right corner of this page.', '7.After the success mark, please check your email for the status of the Bulk Products Operation. If Unsuccessful, please refer to the error message returned in the email.'
    //     ]
    // },
    // {
    //     title: 'How to add another shop',
    //     content: ['1.Go to seller dashboard', '2.Go to \'settings\'', '3.Click \'Add shop\''
    //     ]
    // },
    // {
    //     title: 'How to switch to another shop',
    //     content: ['1.Go home of Shop', '2.Click SWITCH Shop', '3.Click \'Apply\''
    //     ]
    // },
    {
        title: 'How to update shop details',
        content: ['1.Click \'Edit\'', 'Click SAVE CHANGES button'
        ]
    },
    {
        title: 'How to reset your password',
        content: ['1.From the Bazaar Cheap login page,click Forgot Password', '2.Enter your login email and click Submit',
        '3.Fill in verification code sent to your email', '4.Enter new password and submit',

        ]
    },
    {
        title: 'How to contact support',
        content: ['1.Navigate to Bazzar Cheap Help page','2.Login to your Bazzar Cheap account when prompted'
        ]
    },
    {
        title: 'How to create an account',
        content: ['1.Click signup from the navigation bar', '2.Click oin as a shopper of as vendor', '3.Fill in the form and submit'
        ]
    },
    {
        title: 'How to become a vendor',
        content: ['1.Login and then click on your account in the right corner', '2.Select \'Become a vendor\''
        ]
    },

]

