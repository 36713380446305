import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../../context/provider';
import {
  addToWishList,
  getWishList,
} from '../../../../context/actions/products';
import { useHistory } from 'react-router';
import { isLoggedIn } from '../../../../utils/isLoggedIn';
import { FaHeart } from 'react-icons/fa';
import { FaRegHeart } from 'react-icons/fa6';

const WishlistIcon = ({ productId }: { productId: any }) => {
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const { productState, productDispatch } = useContext<any>(GlobalContext);

  const history = useHistory();
  const clickHandler = async (e: any) => {
    e.stopPropagation();
    if (!isLoggedIn()) {
      history.push('/login');
      return;
    }
    setIsBookmarked((prevIsBookmarked) => !prevIsBookmarked);
    await addToWishList(productId)(productDispatch);
    await getWishList()(productDispatch);
  };

  useEffect(() => {
    const initialIsBookmarked = productState?.wishlist?.some(
      (item: any) => item?.product?.id === productId
    );
    setIsBookmarked(initialIsBookmarked);
  }, [productState.wishlist, productId]);

  return (
    <div className="flex text-center justify-center items-center w-6 h-6 bg-gray-50 rounded-full cursor-pointer">
      {isBookmarked ? (
        <FaHeart className="text-secondary" onClick={(e) => clickHandler(e) }/>
      ) : (
        <FaRegHeart  onClick={(e) => clickHandler(e)} />
      )}
    </div>
  );
};

export default WishlistIcon;