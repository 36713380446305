import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonModal,
  IonRippleEffect,
  IonRow,
  IonSpinner,
  IonTextarea,
} from '@ionic/react';
import { GoPencil } from 'react-icons/go';
import {
  addOutline,
  close,
  closeOutline,
  closeSharp,
  locationSharp,
  pencilOutline,
  pencilSharp,
  storefrontSharp,
} from 'ionicons/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { updateStore } from '../../context/actions/store';
import { UPDATE_STORE_ERROR } from '../../context/actions/types';
import useGlobalContext from '../../context/provider';
import { DeviceContext } from '../../deviceType';
import { Store, storeSchema } from '../../types/store';
import ListItem from './listItem';
import LocCard from './editStore';
import classes from './styles.module.scss';
import { useFileUpload } from '../../hooks/files';
import { FolderEnum } from '../../utils/fileUpload';
import ErrorToast from '../error_toast';
import { useHistory, useLocation } from 'react-router';
import EditStore from './editStore';

const StoreInfoCard = ({
  store,
  action,
}: {
  store: storeSchema;
  action: () => void;
}) => {
  const size = useContext<any>(DeviceContext);
  const modal = useRef<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [_store, setStore] = useState<
    Omit<storeSchema, 'location' | 'type' | 'phoneNumber'> & {
      locations: string[];
      types: string[];
      contacts: string[];
    }
  >(
    {} as Omit<storeSchema, 'location' | 'type' | 'phoneNumber'> & {
      locations: string[];
      types: string[];
      contacts: string[];
    }
  );
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (store.id) {
      const { location, type, phoneNumber, ...rest } = store;
      setStore({
        ...rest,
        locations: location!?.split(',')!,
        contacts: phoneNumber?.split(',')!,
        types: type?.split(',')!,
      });
    }
  }, [store]);

  return (
    <IonCard
      className={
        classes._card +
        ' w-full h-fit rounded-xl border-2 border-[rgba(15, 50, 77, 0.478)] p-0 m-0'
      }
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <IonModal
        ref={modal}
        trigger="open-edit-modal"
        backdropDismiss
        isOpen={open}
        className=" "
        style={{
          '--height': size.width >= 768 ? '85dvh' : '100dvh',
          '--width': size.width >= 768 ? 'auto' : '100dvw',
          '--max-width': 'unset',
          '--max-height': 'unset',
          '--border-radius': size.width >= 768 ? '20px' : '0',
        }}
      >
        <div className="size-full flex flex-col itesm-center py-4 px-2">
          <div className=" w-full h-[10%] py-2 md:px-6 px-4 flex flex-row items-center justify-between">
            <div className="w-auto h-full flex flex-row items-center text-2xl font-semibold text-[var(--ion-color-primary)]">
              Edit Store
            </div>
            <IonButton fill="clear" onClick={() => setOpen(false)}>
              <IonIcon
                icon={close}
                slot={'icon-only'}
                color="primary"
              ></IonIcon>
            </IonButton>
          </div>
          <div className=" w-full h-[90%] overflow-y-scroll md:px-6 px-4">
            <EditStore
              close={() => {
                setOpen(false);
              }}
              store={store}
            />
          </div>
        </div>
      </IonModal>
      {loading && (
        <div className={classes.loading}>
          <IonSpinner name="circles" color={'secondary'}></IonSpinner>
        </div>
      )}

      <IonCardContent className={'size-full relative p-0'}>
        <div
          className={
            'w-full h-[25dvh] flex flex-row items-center justify-center bg-gray-900 relative overflow-hidden'
          }
        ></div>

        <div
          className={
            'absolute top-[10%] left-[5%] h-auto w-[18dvh] md:w-[20dvh] aspect-square rounded-full overflow-hidden bg-white border-[1px] border-gray-500'
          }
        >
          <img
            className={'size-full relative object-cover object-center'}
            alt="Store Profile"
            src={store?.logo!}
          />
        </div>
        <div className="absolute w-auto md:w-fit h-fit md:right-[10%] right-0">
          <IonButton
            fill="clear"
            color="secondary"
            id="open-edit-modal"
            size="small"
            className=" text-sm"
            style={{ '--border-radius': '0' }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <GoPencil className="mx-1 text-base"/>
            Edit
          </IonButton>
        </div>
        <IonGrid class="w-full h-auto md:mt-[10%] mt-[18%] px-[5%]">
          <IonRow>
            <IonCol size="12">
              <IonRow
                className={classes._title + ' w-auto font-semibold text-xl '}
                style={size.width < 769 ? { paddingInlineStart: '0' } : {}}
              >
                <IonCol size="12">
                  <div>{_store?.name}</div>
                </IonCol>
              </IonRow>
              <IonRow
                className={
                  classes._slug + ' w-auto font-medium text-sm cursor-pointer'
                }
                style={size.width < 769 ? { paddingInlineStart: '0' } : {}}
              >
                <IonCol size="12" className={''}>
                  <div onClick={() => history.push(`/${_store?.slug}`)}>
                    @{_store?.slug}
                  </div>
                </IonCol>
              </IonRow>
              <IonRow
                className={
                  classes._description +
                  ' py-2 w-full h-fit font-medium text-base'
                }
              >
                <IonCol size="12">
                  <div>{_store?.description}</div>
                </IonCol>
              </IonRow>
              <IonRow className={' py-2 w-full h-fit font-medium text-base'}>
                <IonCol size="12">
                  <div className="w-full h-fit grid auto-rows-auto grid-cols-2  gap-x-2 ">
                    <div
                      className={
                        'w-full h-fit flex flex-col items-center col-span-1'
                      }
                    >
                      <div
                        className={
                          'w-full h-fit font-semibold text-sm text-[var(--ion-color-primary)] flex flex-row justify-items-center'
                        }
                      >
                        Product Categories
                      </div>
                      <div
                        className={
                          'w-full h-fit flex flex-col items-start py-2 px-4 mt-2'
                        }
                      >
                        {store.type?.split(',')!.map((item, index) => (
                          <div
                            key={index}
                            className="w-full h-fit flex flex-row items-center justify-between pb-2 "
                          >
                            <div className="w-fit font-medium text-base text-[var(--ion-color-primary)]">
                              {item}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        'w-full h-fit flex flex-col items-center col-span-1'
                      }
                    >
                      <div
                        className={
                          'w-full h-fit font-semibold text-sm text-[var(--ion-color-primary)] flex flex-row justify-items-center'
                        }
                      >
                        Shop Locations
                      </div>
                      <div
                        className={
                          'w-full h-fit flex flex-col items-start py-2 px-4 mt-2'
                        }
                      >
                        {store.location?.split(',')!.map((item, index) => (
                          <div
                            key={index}
                            className="w-full h-fit flex flex-row items-center justify-between pb-2 "
                          >
                            <div className="w-fit font-medium text-base text-[var(--ion-color-primary)]">
                              {item}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={
                        'w-full h-fit flex flex-col items-center col-span-1'
                      }
                    >
                      <div
                        className={
                          'w-full h-fit font-semibold text-sm text-[var(--ion-color-primary)] flex flex-row justify-items-center'
                        }
                      >
                        Shop Contacts
                      </div>
                      <div
                        className={
                          'w-full h-fit flex flex-col items-start py-2 px-4 mt-2'
                        }
                      >
                        {store.phoneNumber?.split(',')!.map((item, index) => (
                          <div
                            key={index}
                            className="w-full h-fit flex flex-row items-center justify-between pb-2 "
                          >
                            <div className="w-fit font-medium text-base text-[var(--ion-color-primary)]">
                              {item}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default StoreInfoCard;
