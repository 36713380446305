import { axiosInstance } from '../../../utils/axios';
import { Dispatch } from 'react';
import {
  GET_ADMIN_STORES,
  GET_ADMIN_STORES_ERROR,
  GET_ADMIN_STORE,
  GET_ADMIN_STORE_ERROR,
  APPROVE_REJECT_STORE,
  APPROVE_REJECT_STORE_ERROR,
} from '../types';

export const getAdminStores =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/admin/stores`);
      dispatch({ type: GET_ADMIN_STORES, payload: res.data.data });
    } catch (error: any) {
      dispatch({
        type: GET_ADMIN_STORES_ERROR,
        payload: error.response.data.message || error.message,
      });
    }
  };

export const getAdminStore =
  (id: number | string) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/admin/stores/${id}`);
      dispatch({ type: GET_ADMIN_STORE, payload: res.data.data });
    } catch (error: any) {
      dispatch({
        type: GET_ADMIN_STORE_ERROR,
        payload: error.response.data.message || error.message,
      });
    }
  };

export const approveRejectStore =
  (payload: { status: string; storeId: number }) =>
  async (
    dispatch: Dispatch<{
      type: string;
      payload: { status: string; storeId: number };
    }>
  ) => {
    try{
       const res = await axiosInstance.post(`/v1/admin/store/activate`, payload);
       dispatch({ type: APPROVE_REJECT_STORE, payload: res.data.data });
    } catch(error: any){
      dispatch({
        type: APPROVE_REJECT_STORE_ERROR,
        payload: error.response.data.message || error.message,
      });
    }
  };
