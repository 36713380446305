import { axiosInstance } from './axios';

export enum FolderEnum {
  Products = 'products',
  Logos = 'logos',
  Profile = 'profile-pics',
  Banners = 'banners',
}

export type FileUploadType = {
  type?: string;
  files: File[];
  folder: FolderEnum;
};

export const uploadFile = async ({
  files,
  type = 'images',
  folder,
}: FileUploadType) => {
  const formData = new FormData();

  for (const item of files) {
    formData.append(type, item);
  }
  try {
    const res = await axiosInstance.post(
      `v1/upload?folder=${folder}`,
      formData
    );
    return res.data?.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'File upload failed' };
  }
};
