import {
  IonSpinner,
  RefresherEventDetail,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import { GlobalContext } from '../../../context/provider';
import { getStore } from '../../../context/actions/store';
import { GET_STORE_ERROR } from '../../../context/actions/types';
import ItemList from '../../../components/itemList';
import { ProductType } from '../../../types/product';
import { getCurrentStore } from '../../../utils/currentStore';
import ErrorToast from '../../../components/error_toast';
import { useHistory } from 'react-router';
import PageHeader from '../../../components/pageHeader';

const ShopProducts = () => {
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getStoreInfo();
  }, []);

  useEffect(() => {
    if (storeState!?.store) {
      const store = storeState.store;
      const prods = store?.products;
      setProducts(prods);
      setLoading(false);
    }
    if (storeState!?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError!?.response!?.data!?.message
          ? storeState?.storeError!?.response!?.data!?.message
          : storeState?.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
      setLoading(false);
    }
  }, [storeState!?.store, storeState!?.store?.id, storeState!?.storeError]);

  async function getStoreInfo() {
    const currentStore = getCurrentStore();
    await getStore(Number(currentStore))(storeDispatch);
  }

  const handleCreate = () => {
    history.push('/dashboard/create-product');
  };

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    setTimeout(async () => {
      await getStoreInfo();
      e.detail.complete();
    }, 1000);
  };

  return (
    <Layout enableRefresh={true} handleRefresh={handleRefresh}>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {loading && (
        <div className="absolut w-full flex justify-center items-center">
          <IonSpinner color="secondary"></IonSpinner>
        </div>
      )}
      {!loading && (
        <div className="relative w-full h-full  px-2 md:px-4 overflow-y-auto mt-4">
          <PageHeader label="Products"/>
          <div className="w-full mt-4">
            <ItemList
              data={{  listItems: products }}
              plusAction={handleCreate}
              customStyles={{ productsWrapper: 'md:!grid-cols-4' }}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ShopProducts;
