import { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { IonCol, IonGrid, IonRow, RefresherEventDetail } from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import StoreBar from '../../../components/storeBar';
import useGlobalContext from '../../../context/provider';
import { getStore } from '../../../context/actions/store';
import { GET_STORE_ERROR } from '../../../context/actions/types';
import PChart from '../../../components/pieChart';
import { ProductType } from '../../../types/product';
import BChart from '../../../components/barChart';
import MultipleTabCard from '../../../components/multipleTabCard';
import { Store, storeSchema } from '../../../types/store';
import CurrentStoreCard from '../../../components/currentstorecard';
import { getCurrentStore } from '../../../utils/currentStore';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';

const SellerHome = () => {
  const size = useContext(DeviceContext);
  const { storeState, storeDispatch } = useGlobalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const [categoryEngagements, setCategoryEngagements] = useState<
    { name: string; value: number }[]
  >([]);
  const [views, setViews] = useState<
    { id: number | string; product: any; createdAt: string }[]
  >([]);
  const [mostViewed, setMostViewed] = useState<ProductType[]>([]);
  const [mostSearched, setMostSearched] = useState<ProductType[]>([]);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    // this listens for change in the localstorage
    setLoading(true);
    const currentStore = getCurrentStore();
    window.addEventListener('storage', () => {
      setLoading(true);
      fetchStore(currentStore as string);
    });
    fetchStore(currentStore as string);
  }, []);

  useEffect(() => {
    const _products = store.products!?.filter((i, index) => i.active) || [];
    const mostsearched = _products
      .sort((a: ProductType, b: ProductType) => {
        if (a.searchCount! < b.searchCount!) {
          return 1;
        }
        if (a.searchCount! > b.searchCount!) {
          return -1;
        }
        return 0;
      })
      .slice(0, 5);
    const mostviewed = _products
      .sort((a: ProductType, b: ProductType) => {
        if (a.views!.length! < b.views!.length!) {
          return 1;
        }
        if (a.views!.length! > b.views!.length!) {
          return -1;
        }
        return 0;
      })
      .slice(0, 5);
    setViews(store.productViews! || []); // views are set
    setMostViewed(mostviewed);
    setMostSearched(mostsearched);
    const categoryEngagements = extractCategoryEngagements(store.productViews!);
    setCategoryEngagements(categoryEngagements);
  }, [store]);

  useEffect(() => {
    if (storeState.store) {
      const _store = storeState.store;
      setStore(_store); // stoore is set
      setLoading(false);
    }
    if (storeState?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError!?.response!?.data!?.message
          ? storeState?.storeError!?.response!?.data!?.message
          : storeState?.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState]);

  // functions
  const extractCategoryEngagements = (viewList: any[] = []) => {
    let list: { name: string; value: number }[] = [];
    for (let i = 0; i < viewList.length; i++) {
      if (list.some((e) => e.name === viewList[i].product.type)) {
        const index = list.findIndex(
          (e) => e.name === viewList[i].product.type
        );
        const group = {
          name: list[index].name,
          value: list[index].value + 1,
        };
        list[index] = group;
      } else {
        const group = {
          name: viewList[i].product.type,
          value: 1,
        };
        list = [...list, group];
      }
    }
    return list;
  };

  async function fetchStore(_id: string) {
    if (storeState.store) {
      const _store = storeState.store;
      setStore(_store);
      setLoading(false);
      return;
    }
    await getStore(Number(_id))(storeDispatch);
  }

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    setTimeout(async() => {
      await fetchStore(getCurrentStore() as string);
      e.detail.complete();
    }, 1000);
  }

  return (
    <Layout enableRefresh={true} handleRefresh={handleRefresh}>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />

      {loading ? (
        <div className={classes.loading}>
          <Spinner type={'circular'} />
        </div>
      ) : (
        <div className={classes.holder}>
          {size.width > 768 && (
            <IonGrid className={classes.desktopGrid}>
              <IonRow className={classes.container}>
                <IonCol size="8.5" className={classes.leftBar}>
                  <div className={classes.homeGraphs}>
                    <div className={classes.bargraph}>
                      <div className={classes.label}>Product Views</div>
                      <div className={classes.chartHolder}>
                        <BChart
                          errorMessage="No Previous Week Data To Show"
                          data={views}
                          YAxisLabel="Views"
                        />
                      </div>
                    </div>
                    <div className={classes.piechart}>
                      <div className={classes.label}>
                        Engagement By Category
                      </div>
                      <div className={classes.chartHolder}>
                        <PChart data={categoryEngagements} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.products}>
                    <MultipleTabCard
                      data={[
                        {
                          label: 'Most Searched',
                          default: true,
                          data: mostSearched,
                        },
                        { label: 'Most Viewed', data: mostViewed },
                      ]}
                    />
                  </div>
                </IonCol>
                <IonCol size="3.5" className={classes.rightBar}>
                  <StoreBar />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          {size.width <= 768 && (
            <IonGrid className={classes.mobileGrid}>
              <IonRow className={classes.currentStore}>
                <IonCol size="12">
                  <CurrentStoreCard />
                </IonCol>
              </IonRow>
              <IonRow className={classes.homeGraphs}>
                <IonCol size={'12'}>
                  <div className={classes.bargraph}>
                    <div className={classes.label}>Product Views</div>
                    <div className={classes.chartHolder}>
                      <BChart
                        errorMessage="No Data To Show for Last Week"
                        data={views}
                        YAxisLabel="Views"
                      />
                    </div>
                  </div>
                  <div className={classes.piechart}>
                    <div className={classes.label}>Engagement By Category</div>
                    <div className={classes.chartHolder}>
                      <PChart data={categoryEngagements} />
                    </div>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className={classes.products}>
                <IonCol size="12">
                  <div className={classes.holder}>
                    <MultipleTabCard
                      data={[
                        {
                          label: 'Most Searched',
                          default: true,
                          data: mostSearched,
                        },
                        { label: 'Most Viewed', data: mostViewed },
                      ]}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </div>
      )}
    </Layout>
  );
};

export default SellerHome;
