import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import Desktop from './desktop';
import Mobile from './mobile';
import { RiEditBoxLine } from 'react-icons/ri';
import { IoCreate } from 'react-icons/io5';
import { FaCartShopping } from 'react-icons/fa6';
import { FaShareAlt } from 'react-icons/fa';
import { person, storefrontOutline } from 'ionicons/icons';
import testVIDEO from '../../../Assets/video/testvideo.mp4';
import Hls from 'hls.js';
import { IconType } from 'react-icons';
import analytics from '../../../Assets/landing/analytics.svg';
import wishlist from '../../../Assets/landing/wishlist.svg';
import shops from '../../../Assets/landing/shos.svg';
import catalogue from '../../../Assets/landing/catalogue.svg';

export type LandingPageData = {
  showcase?: {
    primary: string;
    secondary: string;
    showcase_stat: {
      label: string;
      icon: string | any;
      statistic: number;
      description: string;
    }[];
  };
  becomevendor?: {
    label: string;
    sub: string;
    video: string;
    steps: {
      path: string;
      icon: ReactNode;
      label: string;
      text: string;
    }[];
  };
  whyus?: {
    label: string;
    sub: string;
    list: { name: string; body: string; icon: any }[];
  };
  contact?: {
    label: string;
    sub: string;
    contacts: string[];
  };
  faqs?: {
    label: string;
    faq: {
      title: string;
      content: string;
    }[];
  };
};

const Landing = () => {
  const size = useContext(DeviceContext);
  const videoRef = useRef<any>(null);
  // this is a mock up object it just gives the structure the landing ppage should have
  const landing_page_data: LandingPageData = {
    showcase: {
      primary: ` Create Your shop, Share, Sell! `,
      secondary:
        'Create your online shop in minutes and reach thousands of customers effortlessly',
      showcase_stat: [
        {
          label: 'Shops',
          icon: storefrontOutline,
          statistic: 233,
          description: 'Join our vibrant marketplace.',
        },
        {
          label: 'Shoppers',
          icon: person,
          statistic: 233,
          description: 'Transform window shoppers  into loyal customers',
        },
      ],
    },
    becomevendor: {
      label: 'Become A Vendor',
      sub: 'Ready to start your online shop? Watch this quick guide to learn how easy it is to create your shop and start reaching customers on Bazaar Cheap',
      video: testVIDEO,
      steps: [
        {
          path: '/registervendor',
          icon: <RiEditBoxLine className={classes.icon_data} />,
          label: 'Register As Vendor',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
        {
          path: '/dashboard/create-store',
          icon: <IoCreate className={classes.icon_data} />,
          label: 'Register Your Shop',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
        {
          path: '/dashboard/store',
          icon: <FaCartShopping className={classes.icon_data} />,
          label: 'Create Products',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
        {
          path: '/dashboard/store#share',
          icon: <FaShareAlt className={classes.icon_data} />,
          label: 'Share Shop Catalog',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
      ],
    },
    whyus: {
      label: 'Why Bazaar Cheap?',
      sub: 'We empower vendors to create and manage online shops effortlessly. Explore the key features that make our platform stand out:',
      list: [
        {
          icon: analytics,
          name: 'Detailed Analytics Dashboard',
          body: 'A comprehensive dashboard providing vendors with insights into customer trends, product performance, and behavior to help make data-driven decisions.',
        },
        {
          icon: catalogue,
          name: 'Products Catalogue',
          body: 'A digital showcase where vendors can upload, organize, and display their products for customers to browse online.',
        },
        {
          icon: shops,
          name: 'Manage Multiple Shops',
          body: 'Enables vendors to oversee and operate multiple online shops seamlessly from a single platform.',
        },
        {
          icon: wishlist,
          name: 'Customer Wish Lists',
          body: 'A tool that allows vendors to track and analyze products customers save, providing valuable insights into customer preferences and demand trends.',
        },
      ],
    },
    contact: {
      label: 'Contact Us',
      sub: 'Got questions or need help? Our team is here to assist you! Fill out the form and we’ll get back to you as soon as possible. Whether it’s setting up your shop, or anything else, we’re just a message away!',
      contacts: ['support@bazaarcheap.com', '+256 784 331 902'],
    },
    faqs: {
      label: 'FAQS',
      faq: [
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
      ],
    },
  };

  useEffect(() => {
    const video = videoRef.current;
    const hls = new Hls({
      debug: true,
      enableWorker: true,
      lowLatencyMode: true,
      backBufferLength: 90,
    });
    const url = landing_page_data.becomevendor!.video;

    if (Hls.isSupported()) {
      hls.loadSource(url);
      hls.attachMedia(video);
      hls.on(Hls.Events.ERROR, (err, data) => {
        console.log(err);
        console.warn('error data', data);
      });
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else {
      console.log('hls not supported');
    }
  }, []);

  return <Desktop data={landing_page_data} video={videoRef} />;
};

export default Landing;
