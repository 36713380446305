import { IonCol, IonRow } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Btn from '../Btn';
import { CONTACT_US, CONTACT_US_ERROR } from '../../context/actions/types';
import { GlobalContext } from '../../context/provider';
import { contactUs } from '../../context/actions/contact_us';
import InputCard, { InputCardTypes } from '../inputField';
import classNames from 'classnames';

interface contactFormType {
  name: string;
  email: string;
  subject: string;
  body: string;
}

const ContactUsForm = () => {
  const { contactUsState, contactUsDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<String>();

  const [form, setForm] = useState<contactFormType>({
    email: '',
    name: '',
    body: '',
    subject: '',
  });
  const [error, setError] = useState<{
    state: boolean;
    message: string | null;
  }>({
    state: false,
    message: null,
  });
  const fields: InputCardTypes[] = [
    {
      borders: false,
      type: 'text',
      placeholder: 'Name',
      name: 'name',
      maxLength: 256,
      minLength: 0,
      clear: false,
      inputType: 'text-field',
      required: true,
      formValues: form,
      value: setForm,
    },
    {
      borders: false,
      type: 'email',
      placeholder: 'Email',
      name: 'email',
      maxLength: 256,
      minLength: 0,
      clear: false,
      inputType: 'text-field',
      required: true,
      formValues: form,
      value: setForm,
    },
    {
      borders: false,
      type: 'text',
      placeholder: 'Subject',
      name: 'subject',
      maxLength: 256,
      minLength: 0,
      clear: false,
      inputType: 'text-field',
      required: true,
      formValues: form,
      value: setForm,
    },
    {
      borders: false,
      type: 'text',
      placeholder: 'Enter Your Message',
      name: 'body',
      maxLength: 1024,
      minLength: 0,
      clear: false,
      inputType: 'text-box',
      required: true,
      formValues: form,
      value: setForm,
    },
  ];
  useEffect(() => {
    if (contactUsState?.contactMessageId) {
      contactUsDispatch({ type: CONTACT_US, payload: null });

      setForm({
        email: '',
        name: '',
        body: '',
        subject: '',
      });
    }
    if (contactUsState?.contactMessageIdError) {
      setError({
        state: true,
        message: contactUsState?.contactMessageIdError?.response?.data,
      });
      contactUsDispatch({ type: CONTACT_US_ERROR, payload: null });
    }
  }, [
    contactUsState?.contactMessageId,
    contactUsState?.contactMessageIdError,
    contactUsDispatch,
  ]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError({
      state: false,
      message: null,
    });

    setLoading(true);
    const user = {
      email: form?.email,
      name: form?.name,
      subject: form?.subject,
      body: form?.body,
    };
    await contactUs(user)(contactUsDispatch);
    setLoading(false);
    setConfirmMessage(
      'We have received your email, will get back to you shortly'
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <IonRow>
        <IonCol>
          {confirmMessage && (
            <p className={classes.message}>{confirmMessage}</p>
          )}
          {error && <p className={classes.error}>{error.message}</p>}
        </IonCol>
      </IonRow>
      <div className="w-full h-auto flex flex-col items-center">
        {fields.map((e) => {
          return (
            <div
              key={e.name}
              className={
                'w-full mb-2 ' +
                classNames({
                  'h-[200px]': e.inputType === 'text-box',
                  'h-fit': e.inputType === 'text-field',
                })
              }
            >
              <InputCard {...e} color="white" background="rgb(17,53,84)" />
            </div>
          );
        })}
      </div>
      <IonRow>
        <IonCol className={classes.button}>
          <Btn match={true} name="Submit" loading={loading} />
        </IonCol>
      </IonRow>
    </form>
  );
};
export default ContactUsForm;
