import { IonGrid, IonRow, IonCol, RefresherEventDetail } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Layout from '../../../components/Layout/user/Layout';
import { searchProducts } from '../../../context/actions/products';
import { getStore } from '../../../context/actions/store';
import {
  GET_STORE_ERROR,
  SEARCH_PRODUCTS,
} from '../../../context/actions/types';
import { GlobalContext } from '../../../context/provider';
import classes from './styles.module.scss';
import StoreProducts from './store-products';
import StorePageHead from './topBar';
import { ProductType } from '../../../types/product';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import { storeSchema } from '../../../types/store';

const SingleStore = () => {
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const location = useLocation();
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [products, setProducts] = useState<ProductType[]>([]);
  const [searchList, setSearchList] = useState<ProductType[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const params = useParams<any>();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const slug = location.pathname.split('/')[1];
    if (slug) {
      fetchStore(slug);
    }
  }, []);

  useEffect(() => {
    if (storeState?.store && storeState.store.slug === params.store) {
      setStore(storeState.store);
      setProducts(storeState.store?.products);
      setLoading(false);
    }
    if (storeState?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState.storeError!?.response!?.data!?.message
          ? storeState.storeError!?.response!?.data!?.message
          : storeState.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState?.store, storeState.storeError]);

  useEffect(() => {
    if(searchText){
      handleSearch();

    }
  }, [searchText]);

  useEffect(() => {
    setSearchList(products);
  }, [products]);

  useEffect(() => {
    setSearchLoading(false);
  }, [searchList]);

  async function fetchStore(e: any) {
    setLoading(true);
    if (storeState?.store && storeState.store.slug === params.store) {
      setStore(storeState.store);
      setProducts(storeState.store?.products);
      setLoading(false);
      return;
    }
    await getStore(e)(storeDispatch);
  }
  const handleSearch = async () => {
    setSearchLoading(true);
    if (!searchText.length) {
      setSearchList(products);
    }
    setTimeout(() => {
      // creates the illusion of a search
      const filtered = products.filter((item: ProductType) => {
        return (
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.type.toLowerCase().includes(searchText.toLowerCase()) ||
          item.details.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setSearchList(filtered);
    }, 700);
  };

  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    setTimeout(async() => {
      const storeSlug = location.pathname.split('/')[1];
      await fetchStore(storeSlug);
      e.detail.complete();
    }, 1000);
  }

  return (
    <Layout enableRefresh={true} handleRefresh={handleRefresh}>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '70vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner type="circles" />
        </div>
      ) : (
        <div className={classes.mainHolder + ' px-4'}>
          <IonGrid className={classes.storeGrid}>
            <IonRow className={classes.topBar}>
              <IonCol size="12">
                <StorePageHead
                  store={store!}
                  setSearchText={setSearchText}
                  searchText={searchText}
                />
              </IonCol>
              <IonCol size="12">
                {searchLoading ? (
                  <Spinner />
                ) : (
                  <StoreProducts
                    store={store}
                    products={searchList}
                    getAll={() => setProducts(store?.products!)}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
    </Layout>
  );
};

export default SingleStore;
