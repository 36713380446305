import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { IonCard } from '@ionic/react';
import { getProducts, searchProducts } from '../../../context/actions/products';
import { GlobalContext } from '../../../context/provider';
import {
  GET_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_ERROR,
} from '../../../context/actions/types';
import ProductBlock from '../../../components/cards/Product_Block';
import { getProductsParams, ProductType } from '../../../types/product';
import ErrorToast from '../../../components/error_toast';
import { DeviceContext } from '../../../deviceType';

const RecoCard = (props: { ref_product: ProductType }) => {
  const size = useContext(DeviceContext);
  const { productState, productDispatch } = useContext<any>(GlobalContext);
  const [data, setData] = useState<ProductType>();
  const [sameStoreProducts, setSameStoreProducts] = useState<ProductType[]>([]);
  const [similarProducts, setSimilarProducts] = useState<ProductType[]>([]);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorCode, setErrorCode] = useState<number>(0);
  useEffect(() => {
    if (props.ref_product?.id) {
      setData(props.ref_product);
    }
  }, [props.ref_product]);

  useEffect(() => {
    if (data?.id) {
      const sameStoreProductsParams = {
        storeId: data?.store?.id?.toString()!,
        // type: data?.type!,
      };
      const similarProductsParams = {
        categories: [data?.type!],
        priceBelow: data?.price!.toString()!,
        priceAbove: data?.price!.toString()!,
      };
      fetchProductsFromSameStore(sameStoreProductsParams);
      fetchSimilarProducts(similarProductsParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // for samestore products
    if (productState.getProducts) {
      const data = productState.getProducts.data;
      setSameStoreProducts(data);
    }
    if (productState?.getProductsError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState.getProductsError!?.response!?.data!?.message
          ? productState.getProductsError!?.response!?.data!?.message
          : productState.getProductsError!?.message
      );
      setErrorCode(productState.getProductsError.code);
      productDispatch({ type: GET_PRODUCTS_ERROR, payload: null });
    }

    // for similar products
    if (productState.searchProducts) {
      const data = productState.searchProducts.data;
      setSimilarProducts(data);
    }
    if (productState?.searchProductsError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState?.searchProductsError!?.response!?.data!?.message
          ? productState?.searchProductsError!?.response!?.data!?.message
          : productState?.searchProductsError!?.message
      );
      setErrorCode(productState?.searchProductsError.code);
      productDispatch({ type: SEARCH_PRODUCTS_ERROR, payload: null });
    }
  }, [
    productState.getProducts,
    productState.getProductsError,
    productState.searchProducts,
    productState.searchProductsError,
  ]);

  async function fetchProductsFromSameStore(e: {
    storeId: string;
    type?: string;
  }) {
    await getProducts(e)(productDispatch);
  }
  async function fetchSimilarProducts(e: getProductsParams) {
    await searchProducts(e)(productDispatch);
  }
  return (
    <IonCard
      className={
        classes.recomCard +
        ' w-full h-auto m-0 relative overflow-hidden border-none rounded-none bg-transparent'
      }
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div
        className={classes.sameStore}
        style={{ display: sameStoreProducts.length === 0 ? 'none' : 'block' }}
      >
        <div className={classes.label}>More Items from This Store</div>

        <div className={'w-full grid grid-cols-2 md:grid-cols-4 gap-2'}>
          {sameStoreProducts!
            ?.filter((item: any) => {
              return item.id !== Number(props.ref_product.id);
            })
            .map((item: any, index) => {
              return (
                  <ProductBlock data={item} key={index}/>
              );
            })}
        </div>
      </div>
      {/** similar products from other stores disabled */}
      <div className={classes.similar} style={{ display: 'none' }}>
        <div className={classes.label}>You May Also Like</div>
        <div   className={'w-full grid grid-cols-2 md:grid-cols-6 gap-2'}
        >
          {similarProducts!
            ?.filter((item: any) => {
              return item.id !== Number(props.ref_product.id);
            })
            .map((item: any, index) => {
              return (
                  <ProductBlock data={item}    key={index}/>
              );
            })}
        </div>
      </div>
    </IonCard>
  );
};

export default RecoCard;
