import { useParams } from "react-router";
import Layout from "../../../../components/Layout/admin";
import classes from "./styles.module.scss";
import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../../context/provider";
import ErrorToast from "../../../../components/error_toast";
import Spinner from "../../../../components/spinner";
import { HiChevronLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { IonSpinner } from "@ionic/react";
import {
  GET_ADMIN_PRODUCT,
  GET_ADMIN_PRODUCT_ERROR,
} from "../../../../context/actions/types";
import { getAdminProduct } from "../../../../context/actions/admin/product";

interface Store {
  id: number;
  name: string;
  type: string;
  status: string;
  createdAt: string;
}
interface Admin {
  id: number | string;
  name: string;
  type: string;
  details: string;
  images: string[];
  status: string;
  price: number;
  store: Store;
}

const AdminProductDetails = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const { adminProductState, adminProductDispatch } =
    useContext<any>(GlobalContext);
  const [product, setProduct] = useState<Admin>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [actionError, setActionError] = useState<{
    state: boolean;
    message: string | null;
  }>({
    state: false,
    message: null,
  });

  useEffect(() => {
    fetchAdminProduct();
  }, []);

  useEffect(() => {
    if (adminProductState?.adminProduct) {
      const product = adminProductState?.adminProduct;
      setProduct(product);
      adminProductDispatch({ type: GET_ADMIN_PRODUCT, payload: null });
    }
    if (adminProductState?.adminProductError) {
      setShowError(true);
      setErrorMessage(adminProductState?.adminProductError);
      adminProductDispatch({ type: GET_ADMIN_PRODUCT_ERROR, payload: null });
    }
  }, [adminProductState, adminProductDispatch]);

  const fetchAdminProduct = async () => {
    setLoading(true);
    await getAdminProduct(id!)(adminProductDispatch);
    setLoading(false);
  };

  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={"error"}
        isOpen={showError}
        onClose={() => {
          setShowError(false);
        }}
      />
      <div className={classes.header}>
        {actionError && <p className={classes.error}>{actionError.message}</p>}
        <div className={classes.sec}>
          <Link to="/admin/dashboard/products">
            <HiChevronLeft className={classes.arrow} />
          </Link>
          <p className={classes.heading}>Product Details</p>
          
        </div>
        <div className={classes.buttons}>
          <div className={classes.sec1}>
            <p className={classes.status}>status:</p>
            {product?.status ? (
              <div
                className={classes.pend}
                style={{
                  backgroundColor:
                    product?.status === "pending"
                      ? "#FFE9D2"
                      : product?.status === "published"
                      ? "#b7f3c5"
                      : product?.status === "deactivated"
                      ? "#e6bab8"
                      : "gray",
                  color:
                    product?.status === "pending"
                      ? "#D68800"
                      : product?.status === "published"
                      ? "#28A745"
                      : product?.status === "deactivated"
                      ? "#E53A34"
                      : "#E0E0E0",
                }}
              >
                {product.status}
              </div>
            ) : (
              <></>
            )}
          </div>
              {product?.status === "pending" ? (
            <div className={classes.sec2}>
              <button
                className={classes.approve}
              >
                  PUBLISH
              </button>
              <button
                className={classes.reject}
              >
                  REJECT              
              </button>
            </div>
          ) : product?.status === "published" ? (
            <div className={classes.sec2}>
              <button
                className={classes.reject}
              >
                  REJECT
              </button>
            </div>
          ) : product?.status === "draft" ? (
            <div className={classes.sec2}>
              <button
                className={classes.approve}
              >
                  PUBLISH
              </button>
              <button
                className={classes.reject}
              >
                  REJECT              
              </button>
            </div>
          ) : null}

        </div>
      </div>
      <div className={classes.main}>
        {loading && (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        )}
        <div className={classes.banner}>
          {product?.images?.length ? (
            product.images.map((image, index) => (
              <img key={index} className={classes.img} src={image} />
            ))
          ) : (
            <div className={classes.noImage}></div>
          )}
        </div>
        <div className={classes.details}>
          <div className={classes.para2}>
            <p>ID</p>
            <p>Name</p>
            <p>Type</p>
            <p>Price</p>
            <p>Store Name</p>
            <p>Details</p>
          </div>
          {product && (
            <div className={classes.para3}>
              <p>{id}</p>
              <p>{product.name}</p>
              <p>{product.type}</p>
              <p>{product.price}</p>
              <p style={{ color: "#007BFF" }}>
                <Link to={`/admin/dashboard/stores/${product.store.id}`}>
                  {product.store.name}
                </Link>
              </p>
              <p>{product.details.replace(/<\/?p>/g, "")}</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AdminProductDetails;
