export const getTotalSearchCount = (products: any[]) => {
  let productSearchCount = 0;
  for (let i = 0; i < products?.length; i++) {
    const searchCount = products[i].searchCount;
    productSearchCount = productSearchCount + searchCount;
  }

  return productSearchCount;
};

export const getProductStatus = (status:string)=>{
  if(status === 'pending'){
    status = 'Pending Approval'
  }

  return status
}
