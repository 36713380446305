import { IonGrid, IonRow } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import ProductBlock from '../cards/Product_Block';
import Option from './sortOption';
import { getProductTypes } from '../../context/actions/products';
import useGlobalContext from '../../context/provider';
import { GET_PRODUCT_TYPES } from '../../context/actions/types';
import ErrorToast from '../error_toast';
import { FiFilter } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import InputCard from '../inputField';
import { ProductType } from '../../types/product';

const ItemList = ({
  data,
  plusAction,
  customStyles = {},
}: {
  data: {
    title?: string;
    listItems: any[];
  };
  plusAction: () => void;
  customStyles?: { productsWrapper?: string };
}) => {
  const { productTypes, productTypesDispatch } = useGlobalContext();
  const [openContext, setOpenContext] = useState<boolean>(false);
  const [activeSortAction, setActiveSortAction] = useState<string | null>(null);
  const [filterOptions, setFilterOptions] = useState<
    { name: string; options: any[] }[]
  >([]);
  const [filteredList, setFilteredList] = useState<any[]>(
    data?.listItems || []
  );
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [activeFilters, setActiveFilters] = useState<Record<string, string>>(
    {}
  );
  const [searchQuery, setSearchQuery] = useState<{ query: string }>({
    query: '',
  });
  const hasFilters = Object.keys(activeFilters).length > 0;

  const categories: string[] = productTypes?.productTypes?.data || [];

  useEffect(() => {
    const list = filteredList;
    if (searchQuery.query) {
      const filter = filteredList.filter((item: ProductType) => {
        return (
          item.name.toLowerCase().includes(searchQuery.query.toLowerCase()) ||
          item.type.toLowerCase().includes(searchQuery.query.toLowerCase()) ||
          item.details.toLowerCase().includes(searchQuery.query.toLowerCase())
        );
      });
      return setFilteredList(filter);
    }
    if (hasFilters) {
      return handleFiltering();
    }
    setFilteredList(data?.listItems || []);
  }, [searchQuery]);
  useEffect(() => {
    if (hasFilters) {
      handleFiltering();
    } else {
      setFilteredList(data?.listItems || []);
    }
  }, [activeFilters]);

  const handleFiltering = () => {
    const { sort, categories, status } = activeFilters;
    let list = [...data?.listItems];
    if (status) {
      list = list.filter((item) => item.status === status);
    }

    if (sort) {
      if (sort === 'old') {
        list = list.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      } else {
        list = list.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
    }

    if (categories?.length) {
      list = list.filter((item) => categories.includes(item.type));
    }

    setFilteredList(list);
  };

  const sortActions: { name: string; id: string }[] = [
    { name: 'Newest', id: 'new' },
    { name: 'Oldest', id: 'old' },
  ];

  const statusOptions: {
    name: string;
    id: string;
  }[] = [
    { name: 'Published', id: 'published' },
    { name: 'Draft', id: 'draft' },
    { name: 'Pending Approval', id: 'pending' },
    { name: 'Rejected', id: 'rejected' },
  ];

  useEffect(() => {
    setActiveSortAction(null);
    setFilterOptions([
      ...filterOptions,
      { name: 'status', options: ['Published', 'Draft'] },
    ]);
    fetchCategories();
  }, []);

  useEffect(() => {
    if (productTypes.productTypes) {
      setFilterOptions([
        ...filterOptions,
        { name: 'category', options: [...productTypes.productTypes?.data] },
      ]);
    }
    if (productTypes.productTypesError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productTypes?.productTypesError.response?.data
          ? productTypes?.productTypesError.response?.data.message
          : productTypes?.productTypesError.message
      );
      productTypes({ type: GET_PRODUCT_TYPES, payload: null });
    }
  }, [productTypes]);

  const fetchCategories = async () => {
    if (productTypes.productTypes?.data?.length) {
      return setFilterOptions([
        ...filterOptions!,
        { name: 'category', options: [...productTypes.productTypes?.data] },
      ]);
    }
    await getProductTypes()(productTypesDispatch);
  };

  const addSort = (id: string) => {
    if (id === activeSortAction) {
      setActiveSortAction(null);
      setActiveFilters((prev) => ({ ...prev, sort: 'new' }));
    } else {
      setActiveSortAction(id);
      setActiveFilters((prev) => ({ ...prev, sort: id }));
    }
  };

  const handleCategoryFilter = (value: string) => {
    if (categoryFilters.includes(value)) {
      const newFilter = categoryFilters.filter((e) => e != value);
      setCategoryFilters(newFilter);
      setActiveFilters((prev) => ({
        ...prev,
        categories: newFilter.join(','),
      }));
    } else {
      setCategoryFilters((prev) => [...prev, value]);
      setActiveFilters((prev) => ({
        ...prev,
        categories: [...categoryFilters, value].join(','),
      }));
    }
  };

  const handleStatusFilter = (id: string) => {
    setStatusFilter(id);
    if (id === statusFilter) {
      setStatusFilter(null);
      setActiveFilters((prev) => ({ ...prev, status: 'published' }));
    } else {
      setStatusFilter(id);
      setActiveFilters((prev) => ({ ...prev, status: id }));
    }
  };

  const handleClearFilter = () => {
    setActiveFilters({});
    setOpenContext(false);
  };

  return (
    <div className="w-full">
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div className="w-full">
        {data.title && (
          <p className="font-semibold mt-4 text-lg text-primary">
            {data.title}
          </p>
        )}
        <div className="bg-white py-3 px-3 mt-2 rounded-lg border-[1px] border-[rgba(15,50,77,0.1)] grid grid-flow-row-dense grid-cols-5 gap-2">
          <div className=" relative col-span-2 md:col-span-1 h-auto">
            <div
              className="h-full w-fit flex items-center gap-1 cursor-pointer text-[var(--ion-color-primary)]"
              onClick={() => {
                setOpenContext(!openContext);
              }}
            >
              <span className="relative">Filter</span>
              <div className="relative">
                <FiFilter />
                {hasFilters && (
                  <span className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-secondary text-white text-xs rounded-full px-1">
                    {Object.keys(activeFilters)?.length}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className=" h-auto col-span-5 md:col-span-3 flex items-center justify-end ">
            <div className="h-fit flex items-center w-full md:w-[50%]">
              <InputCard
                inputType="text-field"
                name="query"
                placeholder="Search product"
                required
                type="search"
                value={setSearchQuery}
                clear
                borders
                formValues={searchQuery}
              />
            </div>
          </div>
          <div className="col-span-3 md:col-span-1 h-auto flex items-center justify-end py-1">
            <button
              onClick={plusAction}
              className="bg-secondary text-white flex items-center rounded-md py-1 px-2 h-full"
            >
              <span>Add Product</span>
              <GoPlus className="cursor-pointer text-2xl" />
            </button>
          </div>
        </div>
        <div className={classes.actions}>
          {openContext && (
            <div className="w-full bg-white px-3 mt-2 py-3 rounded-lg border-[1px] border-[rgba(15,50,77,0.1)]">
              <div className="w-full">
                <p className=" text-sm md:text-base font-medium">Sort By</p>
                <div className="mt-2 w-full flex gap-2">
                  {sortActions.map((item, index) => {
                    return (
                      <Option
                        key={item.id}
                        item={item.name}
                        onClick={() => addSort(item.id)}
                        isActive={item.id === activeSortAction}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="w-full mt-2">
                <p className=" text-sm md:text-base font-medium">Status</p>
                <div className="mt-2 w-full flex gap-2 flex-wrap">
                  {statusOptions.map((item, index) => {
                    return (
                      <Option
                        key={item.id}
                        item={item.name}
                        onClick={() => handleStatusFilter(item.id)}
                        isActive={item.id === statusFilter}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="w-full mt-2">
                <p className=" text-sm md:text-base font-medium">Cartegory</p>
                <div className="mt-2 w-full flex gap-2 flex-wrap">
                  {categories.map((item, index) => {
                    return (
                      <Option
                        key={item}
                        item={item}
                        onClick={() => handleCategoryFilter(item)}
                        isActive={categoryFilters.includes(item)}
                      />
                    );
                  })}
                </div>
                <div className="w-full mt-6 flex justify-end ">
                  <button
                    onClick={handleClearFilter}
                    className="rounded-md  px-2 py-[2px] border border-primary border-solid text-sm text-primary font-semibold"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className={`w-full grid grid-cols-2 mt-2  md:grid-cols-3 gap-2 ${customStyles.productsWrapper}`}
        >
          {filteredList.map((item, index) => {
            return (
              <ProductBlock
                key={index}
                data={item}
                isVendorView={true}
                customStyles={{ wrapper: 'h-[300px] md:h-[450px]' }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ItemList;
