import React from 'react';
import { useHistory } from 'react-router';
import { storeSchema } from '../../../types/store';

const StoreCard = (props: {
  data: storeSchema;
}) => {
  const {logo, name, type, location} = props.data
  const history = useHistory();

  const backgroundStyle = logo?.trim()?.length
  ? { backgroundImage: `url(${logo})` }
  : { backgroundImage: 'linear-gradient(to bottom right, #a94064, #ffc0cb)' };

  return (
    <div
    className="relative w-[49%] h-96 md:w-[19.5%] md:h-96 bg-cover bg-center  overflow-hidden shadow-lg text-secondary"
    style={backgroundStyle}
  >
    {/* Overlay */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>

    {/* Content */}
    <div className="absolute inset-0 flex flex-col justify-center items-center text-white text-center px-4">
      <h1 className="text-2xl font-bold tracking-wide capitalize">{name}</h1>
      <p className="text-lg font-medium mt-2">{type}</p>
      <p className="text-sm font-light mt-2 italic">{location}</p>

      <button
      onClick={() => {
        history.push({
          pathname: props.data.slug,
          state: { id: props.data.id },
        });
      }}
      className="mt-4 px-4 py-2 text-xs md:text-sm bg-white text-black font-semibold uppercase tracking-wide border border-black hover:bg-gray-100">
        Shop Now
      </button>
    </div>
  </div>
  );
};

export default StoreCard;
