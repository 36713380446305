import classes from './styles.module.scss';
import { IoSearchOutline } from 'react-icons/io5';
import { LuFilter } from 'react-icons/lu';

const Header = ({title}: any) => {
  return (
    <div className={classes.container}>
      <div className={classes.sec1}>
        <p>{title}</p>
        {/* <div className={classes.search_container}>
          <input placeholder="Search" />
          <IoSearchOutline className={classes.search_icon} />
        </div> */}
      </div>
      <div className={classes.sec2}>
        <div className={classes.select}>
          <select className={classes.filter} name="filter">
            <option value="Filter">Filter</option>
            <option value=""></option>
          </select>
          <LuFilter className={classes.select_icon} />
        </div>
        <div>
          <select className={classes.group} name="group">
            <option value="">Group By:</option>
            <option value=""></option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Header;
