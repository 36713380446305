import { IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useContext } from 'react';
import ProductBlock from '../../../components/cards/Product_Block';
import classes from './styles.module.scss';
import { ProductType } from '../../../types/product';
import { storeSchema } from '../../../types/store';
import { DeviceContext } from '../../../deviceType';

const StoreProducts = ({
  products,
  getAll,
  store,
}: {
  getAll: () => void;
  products: ProductType[];
  store: storeSchema;
}) => {
  return (
    <div className="w-full h-full px-2 mt-2">
      <IonGrid className="p-0 w-full">
        {/* <IonRow className={classes.recentItems}>
          <IonCol>
            <div className={classes.recentLabel} onClick={getAll}>
              All
            </div>
          </IonCol>
        </IonRow> */}
        <p className="text-md font-semibold ">Store Products</p>
        <IonRow             className={'w-full grid grid-cols-2 md:grid-cols-6 gap-2'}
        >
          {products?.length > 0 ? (
            products.map((item: any, index: number) => (
              item.status === 'published' &&
                <ProductBlock
                  data={{ ...item, store }}
                  key={index}
                />
            ))
          ) : (
            <div className={classes.noResults}>
              Looks like there's nothing here
            </div>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default StoreProducts;
