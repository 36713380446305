import React, { useContext, useState, useEffect } from 'react';
import Layout from '../../../components/Layout/user/Layout';
import useGlobalContext, { GlobalContext } from '../../../context/provider';
import classes from './styles.module.scss';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonIcon,
  IonSpinner,
} from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import { pencil } from 'ionicons/icons';
import { updateUser } from '../../../context/actions/user';
import ErrorToast from '../../../components/error_toast';
import { UPDATE_USER_ERROR } from '../../../context/actions/types';
import { User } from '../../../types/user';

const Profile = () => {
  const device = useContext(DeviceContext);

  const { userDispatch } = useGlobalContext();
  const { userState } = useContext<any>(GlobalContext);
  const [userDetails, setUserDetails] = useState({} as User);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<{
    type: 'error' | 'warning' | 'sucess';
    message: string;
  }>({
    type: 'sucess',
    message: '',
  });

  useEffect(() => {
    const getUser = async () => {
      const user = await userState.getUser;
      setUserDetails(user);
    };
    getUser();
  }, [userState.getUser]);

  // Validate the form whenever the profile state changes
  useEffect(() => {
    const isValid =
      userDetails?.firstName?.trim() !== '' &&
      userDetails?.lastName?.trim() !== '' &&
      userDetails?.email?.trim() !== '' &&
      userDetails?.phoneNumber?.trim() !== '';
    setIsFormValid(isValid);
  }, [userDetails]);

  const toggleEditMode = () => setIsEditing(!isEditing);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const dataToUpload = {
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      phoneNumber: userDetails?.phoneNumber,
    };

    await updateUser({ id: userDetails?.id, data: dataToUpload })(userDispatch);

    setLoading(false);
    setIsEditing(false);
  };

  useEffect(() => {
    if (userState.updateUser) {
      setUserDetails(userState.updateUser);
      setToastMessage({
        type: 'sucess',
        message: 'Your details have been updated successfully.'
      });
      setLoading(false);

      setIsToastOpen(true);
      setIsEditing(false);
    }
    if (userState.updateUserError) {
      setUserDetails(userState.getUser);
      setToastMessage({
        type: 'error',
        message: userState.updateUserError!?.response!?.data!?.message
          ? userState.updateUserError!?.response!?.data!?.message
          : userState.updateUserError!?.message
      });
      setLoading(false);

    setIsEditing(false);
      setIsToastOpen(true);
      userDispatch({ type: UPDATE_USER_ERROR, payload: null });
    }
  }, [userState, userDispatch]);

  return (
    <Layout>
      <ErrorToast
        message={toastMessage?.message}
        type={toastMessage?.type}
        isOpen={isToastOpen}
        onClose={() => {
          setIsToastOpen(false);
        }}
      />

      <div className={classes.wrapper}>
        <form
          onSubmit={handleSubmit}
          className={`${device.width > 600 && classes['lg-form']}`}
        >
          <IonGrid>
            <IonText className={classes['form-title']}>
              <h2>Profile Details</h2>
            </IonText>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">First name</IonLabel>
                  <IonInput
                    name="firstName"
                    value={userDetails?.firstName}
                    onIonChange={handleInputChange}
                    readonly={!isEditing}
                    required
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Last name</IonLabel>
                  <IonInput
                    name="lastName"
                    value={userDetails?.lastName}
                    onIonChange={handleInputChange}
                    readonly={!isEditing}
                    required
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem>
                  <IonLabel position="floating">Phone Number</IonLabel>
                  <IonInput
                    name="phoneNumber"
                    type="tel"
                    value={userDetails?.phoneNumber}
                    onIonChange={handleInputChange}
                    readonly={!isEditing}
                    required
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            {!isEditing && (
              <>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating">Email</IonLabel>
                      <IonInput
                        name="email"
                        type="email"
                        value={userDetails?.email}
                        readonly={true}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                {userDetails?.role === 'seller' && (
                  <>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonLabel position="floating">
                            Number of shops
                          </IonLabel>
                          <IonInput
                            value={userDetails?.stores?.length}
                            readonly={true}
                          />
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonLabel position="floating">No of views</IonLabel>
                          <IonInput
                            value={userDetails?.views?.length}
                            readonly={true}
                          />
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </>
                )}
              </>
            )}

            <IonRow>
              <IonCol className={classes['btn-col']}>
                {!isEditing ? (
                  <IonButton
                    fill="clear"
                    onClick={toggleEditMode}
                    className={classes['edit-btn']}
                  >
                    Edit Profile
                    <IonIcon size="small" slot="start" icon={pencil}></IonIcon>
                  </IonButton>
                ) : (
                  <IonButton type="submit" disabled={!isFormValid}>
                    Update profile
                    {loading && <IonSpinner slot="end"></IonSpinner>}
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </div>
    </Layout>
  );
};

export default Profile;
