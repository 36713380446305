import {
  GET_ADMIN_STORE,
  GET_ADMIN_STORE_ERROR,
  GET_ADMIN_STORES,
  GET_ADMIN_STORES_ERROR,
  APPROVE_REJECT_STORE,
  APPROVE_REJECT_STORE_ERROR
} from '../../actions/types';

export interface adminStoreState {}
export const adminStoreInitialState: adminStoreState = {
  adminStores: null,
  getAdminStoresError: null,
  adminStore: null,
  getAdminStoreError: null,
  approveRejectStore: null,
  approveRejectStoreError: null,
};

const adminStoreReducer = (
  state: adminStoreState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_ADMIN_STORES:
      return {
        ...state,
        getAdminStoresError: null,
        adminStores: action.payload,
      };
    case GET_ADMIN_STORES_ERROR:
      return {
        ...state,
        adminStores: null,
        getAdminStoresError: action.payload,
      };
    case GET_ADMIN_STORE:
      return {
        ...state,
        getAdmnStoreError: null,
        adminStore: action.payload,
      };
    case GET_ADMIN_STORE_ERROR:
      return {
        ...state,
        adminStore: null,
        getAdmnStoreError: action.payload,
      };
    case APPROVE_REJECT_STORE:
      return {
        ...state,
        approveRejectStoreError: null,
        approveRejectStore: action.payload,
      };
    case APPROVE_REJECT_STORE_ERROR:
      return {
        ...state,
        approveRejectStore: null,
        approveRejectStoreError: action.payload,
      };
    default:
      return state;
  }
};

export default adminStoreReducer;
