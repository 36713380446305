import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent
} from '@ionic/react';
import { useContext } from 'react';
import { DeviceContext } from '../../../deviceType';
import Desktop from './desktop';
import Mobile from './mobile';
import { GlobalContext } from '../../../context/provider';
import Spinner from '../../spinner';

interface LayoutProps {
  children: React.ReactNode;
  enableRefresh?: boolean;
  handleRefresh?: (event: CustomEvent) => void;
}

const Layout = ({
  children,
  enableRefresh = false,
  handleRefresh
}: LayoutProps) => {
  const size = useContext(DeviceContext);
  const { userState } = useContext<any>(GlobalContext);
  const user = userState.getUser;

  return (
    <IonPage style={{ height: '100%', overflow: 'hidden !important' }}>
      <IonContent>
        {/* Pull to Refresh */}
        {enableRefresh && handleRefresh &&(
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
        )}

        {user?.id ? (
          size.width > 768 ? (
            <Desktop user={user} children={children} />
          ) : (
            <Mobile user={user} children={children} />
          )
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Layout;
