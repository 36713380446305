import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import classes from './styles.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import { storeSchema } from '../../../types/store';
import Spinner from '../../../components/spinner';
import { DeviceContext } from '../../../deviceType';
import gsap from 'gsap';
import { getCurrentStore, setCurrentStore } from '../../../utils/currentStore';
import { ellipsisHorizontal, closeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import useGlobalContext from '../../../context/provider';
import {
  DELETE_STORE_ERROR,
  UPDATE_USER_ERROR,
} from '../../../context/actions/types';
import InputCard from '../../../components/inputField';
import { User } from '../../../types/user';
import { updateUser } from '../../../context/actions/user';
import ErrorToast from '../../../components/error_toast';

export const StoreTab = ({ stores }: { stores: storeSchema[] }) => {
  const size = useContext<any>(DeviceContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [_stores, setStores] = useState<storeSchema[]>([] as storeSchema[]);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    if (stores?.length) setLoading(false);
    setStores(stores);
  }, [stores]);

  return (
    <div className={classes.storetab}>
      {loading && (
        <div className={classes.loading}>
          <Spinner />
        </div>
      )}
      {!loading && (
        <IonGrid>
          <IonRow className={classes.storeInfo}>
            <IonCol>
              <div className={classes.listcontainer}>
                {/* <div
                  className={classes.listheadbar}
                  style={{ height: size.width >= 768 ? '15%' : '10%' }}
                >
                  <div className={classes.label}>Stores</div>
                  <div className={classes.actions}>
                    <IonButton
                      color="secondary"
                      fill="solid"
                      onClick={() => {
                        history.push('/dashboard/create-store');
                      }}
                    >
                      Add Store
                    </IonButton>
                  </div>
                </div> */}
                <div
                  className={classes.storelist}
                  style={{ height: size.width >= 768 ? '83%' : '88%' }}
                >
                  {_stores.map((item, index) => (
                    <StoreCard data={item} key={index} />
                  ))}
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </div>
  );
};

const StoreCard = ({ data }: { data: storeSchema }): JSX.Element => {
  const size = useContext<any>(DeviceContext);
  const [open, setOpen] = useState<boolean>(false);
  const cardRef = useRef<any>();
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  const currentStore = Number(getCurrentStore());
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const history = useHistory();
  const { storeState, storeDispatch } = useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const options = [
    {
      name: 'Go To Store',
      action: () => {
        setCurrentStore({ id: store.id!, name: store.name! });
        setOptionsOpen(false);
        setOpen(false);
        history.push('store');
      },
    },
  ];

  useEffect(() => {
    setStore(data);
  }, [data]);
  useEffect(() => {
    dimensions();
  }, [open]);
  useEffect(() => {
    if (optionsOpen) {
      setOpen(true);
    }
  }, [optionsOpen]);
  useEffect(() => {
    if (storeState.store) {
    }
    if (storeState?.storeError?.request?.status === 500) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError!?.response!?.data!?.message
          ? storeState?.storeError!?.response!?.data!?.message
          : storeState?.storeError!?.message
      );
    }
  }, [storeState]);

  const dimensions = () => {
    const card = cardRef.current;
    if (open && card) {
      if (size.width >= 769) {
        gsap.to(card, {
          height: '25vh',
          rotation: 0,
          yoyo: false,
          ease: 'sine.inOut',
          duration: 0.15,
        });
      } else {
        gsap.to(card, {
          height: '45vh',
          rotation: 0,
          yoyo: false,
          ease: 'sine.inOut',
          duration: 0.15,
        });
      }
    } else {
      if (size.width >= 769) {
        gsap.to(card, {
          height: '10vh',
          rotation: 0,
          yoyo: false,
          ease: 'sine.inOut',
          duration: 0.15,
        });
      } else {
        gsap.to(card, {
          height: '15vh',
          rotation: 0,
          yoyo: false,
          ease: 'sine.inOut',
          duration: 0.15,
        });
      }
    }
  };
  return (
    <div
      className={classes.storeCard + ' ion-activatable ripple-parent circle'}
      onClick={() => {
        setOpen(!open);
      }}
      ref={cardRef}
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {optionsOpen && (
        <div
          className={classes.optionsBackdrop}
          onClick={(e) => {
            e.stopPropagation();
            setOptionsOpen(false);
          }}
        >
          <div
            className={classes.options}
            style={{ width: size.width >= 768 ? '10vw' : '30vw' }}
          >
            {options.map((item: any) => {
              return (
                !item.hidden && (
                  <div
                    className={classes.item}
                    onClick={item.action}
                    style={{
                      height: '6vh',
                      color:
                        item.name === 'Delete' ? '#FF0E0E' : 'rgb(15, 50, 77)',
                    }}
                  >
                    {item.name}
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}
      <IonGrid className={classes.grid}>
        <IonRow className={classes.container}>
          <IonCol
            size={size.width >= 768 ? '7' : '10'}
            className={classes.left}
            style={{ padding: size.width <= 768 ? '4% 1%' : '' }}
          >
            {(size.width >= 768 || !open) && (
              <div
                className={classes.logo}
                style={{
                  width: open ? '30%' : 'auto',
                  height: open ? 'auto' : '100%',
                }}
              >
                {/* remove logo */}
                <img src={store.banner!} alt="profile"></img>
              </div>
            )}
            <div className={classes.info}>
              <div
                className={classes.name}
                style={{
                  color: currentStore === store.id ? '#A94064' : '#0f324d',
                  height: open ? '20%' : '100%',
                }}
              >
                {store.name}
              </div>
              {open && <div className={classes.bio}>{store.description}</div>}
            </div>
          </IonCol>
          <IonCol
            size={size.width >= 768 ? '5' : '2'}
            className={classes.right}
          >
            <div
              className={classes.options}
              style={{ height: open ? '20%' : '100%' }}
            >
              <IonButton
                fill="clear"
                slot={'icon-only'}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setOptionsOpen(true);
                }}
              >
                <IonIcon icon={ellipsisHorizontal} color="primary" />
              </IonButton>
            </div>
            {open && <div className={classes.info}></div>}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export const AccountTab = () => {
  const { userState, userDispatch } = useGlobalContext();
  const [user, setUser] = useState<User>({} as User);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const size = useContext(DeviceContext);
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const _user = userState.getUser;
    setUser(_user);
  }, []);
  useEffect(() => {
    if (user?.id) {
      setLoading(false);
    }
  }, [user]);
  useEffect(() => {
    if (userState.updateUser) {
      setUser(userState.updateUser);
    }
    if (userState.updateUserError) {
      setUser(userState.getUser);
      setMessage(
        userState.updateUserError!?.response!?.data!?.message
          ? userState.updateUserError!?.response!?.data!?.message
          : userState.updateUserError!?.message
      );
      setLoading(false);
      userDispatch({ type: UPDATE_USER_ERROR, payload: null });
    }

    setIsEditing(false);
  }, [userState.updateUser]);

  const editUserInfo = async () => {
    setLoading(true);
    const { id, role, bookmarks, stores, views, email, ...rest } = user as User;
    await updateUser({ id, data: rest })(userDispatch);
  };
  return loading ? (
    <div className={classes.loading}>
      <Spinner type={'crescent'}></Spinner>
    </div>
  ) : (
    <div className={classes.accountTab}>
      <div
        className={classes.info}
        style={{ padding: size.width >= 768 ? '2% 4%' : '2%' }}
      >
        <div className={classes.secLabel}>Account Details</div>
        <div className={classes.btns}>
          {message && (
            <div className={classes.error}>
              Error:{' ' + message}{' '}
              <span>
                <IonButton
                  fill="clear"
                  color="secondary"
                  onClick={() => {
                    setMessage(null);
                  }}
                >
                  <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                </IonButton>
              </span>
            </div>
          )}
          {!isEditing && (
            <IonButton
              color="primary"
              fill="clear"
              onClick={() => {
                setIsEditing(true);
              }}
            >
              Edit
            </IonButton>
          )}
          {isEditing && (
            <IonButton
              color="secondary"
              fill="clear"
              onClick={() => {
                setUser(userState.getUser);
                setIsEditing(false);
              }}
            >
              Cancel
            </IonButton>
          )}
          {isEditing && (
            <IonButton
              color="secondary"
              fill="solid"
              onClick={() => {
                editUserInfo();
              }}
            >
              Save
            </IonButton>
          )}
        </div>
        {Object.keys(user as User)
          .filter((e: any, i) => {
            const except = ['id', 'bookmarks', 'views', 'stores', 'role'];
            return !except.includes(e);
          })
          .map((e: any, i) => {
            return {
              value: user[e as keyof User],
              name: e,
              placeholder:
                (e as string).charAt(0).toUpperCase() + (e as string).slice(1),
            };
          })
          .map((e, i) => (
            <div
              className={classes.item}
              key={i}
              style={{
                padding: size.width >= 768 ? '0 10px' : '',
                margin: size.width >= 768 ? '5px 0' : '10px 0',
              }}
            >
              <div className={classes.label}>{e.placeholder}</div>
              {isEditing ? (
                <div className={classes.input}>
                  <InputCard
                    name={e.name}
                    placeholder={e.placeholder}
                    value={setUser}
                    disabled={e.name === 'email'}
                    type={e.name === 'phoneNumber' ? 'tel' : 'text'}
                    maxLength={e.name === 'phoneNumber' ? 10 : 200}
                    formValues={user}
                    inputType={'text-field'}
                    required
                  />
                </div>
              ) : (
                <div className={classes.field}>{e.value}</div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
