import { useContext } from 'react';
import { IonItem, IonRow, IonCol, IonIcon } from '@ionic/react';
import Layout from '../../../components/Layout/user/Layout';
// import Profile from '../profile';
import classes from './styles.module.scss';
import {
  accountSettingRoutes,
  myAccountRoutes,
} from '../../../utils/user/routes';
// import { DeviceContext } from '../../../deviceType';
import { GlobalContext } from '../../../context/provider';

const Account = () => {
  // const device = useContext(DeviceContext);
  const { userState } = useContext<any>(GlobalContext);
  const userDetails = userState.getUser;

  return (
    <Layout>
      <div className={classes['content-wrapper']}>
        <div className={classes.greeting}>
          <h3>Welcome, {userDetails?.firstName || userDetails?.lastName}</h3>
          <h6>{userDetails?.email}</h6>
        </div>
        <IonRow className={classes.nav}>
          {/* TODO: change to split view. */}
          {/* <IonCol size='12' size-lg={3} size-md={4} size-sm={5}> */}
          <IonCol size="12" className={classes['temp-menu-col']}>
            <div className={classes.menu}>
              <IonItem lines="none" className={classes['section-head']}>
                My Account
              </IonItem>
              {myAccountRoutes.map((item, index) => (
                <IonItem
                  button
                  routerLink={item.route}
                  key={index}
                  detail={true}
                  lines="none"
                >
                  <IonIcon slot="start" icon={item.icon} size="small"></IonIcon>
                  {item.name}
                </IonItem>
              ))}
              <IonItem lines="none" className={classes['section-head']}>
                Account Settings
              </IonItem>
              {accountSettingRoutes.map((item, index) => (
                <IonItem
                  button
                  routerLink={item.route}
                  key={index}
                  className={`${
                    item.name === 'Delete Account' && classes['delete-item']
                  }`}
                  detail={true}
                  lines="none"
                >
                  <IonIcon slot="start" icon={item.icon}></IonIcon>
                  {item.name}
                </IonItem>
              ))}
            </div>
          </IonCol>

          {/* TODO: change to split view. */}
          {/* {device.width >= 575 && (
						<IonCol size='12' size-lg={9} size-md={8} size-sm={7}>
							<div className={classes.content}>
								<Profile showLayout={false} />
							</div>
						</IonCol>
					)} */}
        </IonRow>
      </div>
    </Layout>
  );
};

export default Account;
