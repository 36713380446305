import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import classes from './style.module.scss';
import { getDashboardRoutes } from '../../../../utils/routes';
import { getCurrentUser } from '../../../../utils/getClientInfo';
import { getCurrentStore } from '../../../../utils/currentStore';
import { authService } from '../../../../utils/authService';

const SellerMenu = () => {
  const location = useLocation();
  const [state, setState] = useState<string>('');
  const history = useHistory();
  const token = getCurrentUser();
  const currentStore = getCurrentStore();
  const storeSlug = token.stores!.find(
    (i, n) => i.id === Number(currentStore!)
  );

  useEffect(() => {
    const path = history.location.pathname;
    setState(path);
  }, []);
  const dashboardRoutes = useMemo(() => {
    return getDashboardRoutes({ location });
  }, [location.pathname]);
  return (
    <IonGrid className={classes.container}>
      <IonRow className={classes.main}>
        <IonCol className={classes._holder}>
          <IonList>
            {dashboardRoutes.map((item, index) => {
              if (!item.isAux && item.icon) {
                return (
                  <IonButton
                    fill="clear"
                    routerLink={
                      item.path.includes(':')
                        ? item.path.split(':')[0] + storeSlug?.slug
                        : item.path
                    }
                    key={index}
                    className={classes.btn}
                    size="large"
                    color={state === item.path ? 'secondary' : 'primary'}
                  >
                    <IonIcon
                      slot="start"
                      icon={item.icon}
                      className={classes.btn_icon}
                    ></IonIcon>
                    <IonLabel
                      style={{
                        color:
                          state === item.path
                            ? 'var(--ion-color-secondary)'
                            : 'var(--ion-color-primary)',
                      }}
                    >
                      {item.name}
                    </IonLabel>
                  </IonButton>
                );
              }
              return null;
            })}
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className={classes.last}>
        <IonCol>
          <IonList>
            {dashboardRoutes.map((item, index) => {
              if (item.isAux && item.icon) {
                return (
                  <IonButton
                    fill="clear"
                    className={classes.btn}
                    key={index}
                    onClick={() => {
                      // TODO: check logic validity
                      item.path === '/login' && authService.logoutUser();
                      history.push(item.path);
                    }}
                    color={state === item.path ? 'secondary ' : 'primary'}
                  >
                    <IonIcon
                      slot="start"
                      icon={item.icon}
                      size="large"
                      className={classes.btn_icon}
                    ></IonIcon>
                    <IonLabel
                      style={{
                        color:
                          state === item.path
                            ? 'var(--ion-color-secondary)'
                            : 'var(--ion-color-primary)',
                      }}
                    >
                      {item.name}
                    </IonLabel>
                  </IonButton>
                );
              }
              return null;
            })}
          </IonList>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default SellerMenu;
