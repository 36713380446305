import React from 'react';
const Option = ({
  item,
  isActive,
  onClick
}: {
    item:string,
    isActive: boolean,
    onClick: () => void
}) => {

  return (
    <div
    key={item}
    className={`w-auto text-xs md:text-sm px-2 py-1 rounded-md cursor-pointer border ${
      isActive
        ? 'border-secondary'
        : 'border-gray-200'
    }`}
    onClick={onClick}
  >
    {item}
  </div>
  );
};

export default Option;
