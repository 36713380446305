/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { IonRippleEffect, IonIcon } from '@ionic/react';
import { addOutline, addSharp } from 'ionicons/icons';
import Delete from './btn';
import { useFileUpload } from '../../hooks/files';

const ImageCarouselInput = ({
  handleInput,
  editable,
  images = [],
}: {
  handleInput: (value: any, name: string) => void;
  editable: boolean;
  images?: string[];
}) => {
  const inputRef = useRef<any>();
  const [_images, setImages] = useState<any[]>([]);
  const [current, setCurrent] = useState<number>(0);
  const [error, setError] = useState<{ state: boolean; message: string }>({
    state: false,
    message: '',
  });
  useEffect(() => {
    if (images) setImages(images);
  }, []);
  useEffect(() => {
    handleInput(_images, 'images');
  }, [_images]);

  function _handleInput(e: any) {
    const fileList: FileList = inputRef.current.files;
    const filtered: File[] = [];
    if (fileList.length + _images.length > 3) {
      return setError({
        state: true,
        message: 'Can Only Select A Maximum of 3 Images',
      });
    }
    for (const file of fileList) {
      if (file.size > Math.floor(6 * 1048576)) {
        setError({
          state: true,
          message: ' Maximum Filesize is 6Mb ',
        });
      } else {
        filtered.push(file);
      }
    }
    setImages([..._images, ...filtered]);
    setCurrent(e.target?.files?.length - 1);
  }
  const handleAdd = (e: any) => {
    e.stopPropagation();
    setError({
      state: false,
      message: '',
    });
    inputRef.current.click();
  };

  return (
    <div className={classes.imgHolder}>
      {editable && (
        <div
          className={classes.input + ' ion-activatable ripple-parent circle'}
          onClick={handleAdd}
          style={{ display: _images.length > 0 ? 'none' : 'flex' }}
        >
          <p>Click To Add Image (Max 3)</p>
          <IonRippleEffect></IonRippleEffect>
          <input
            type="file"
            multiple
            ref={inputRef}
            max={3}
            accept="image/*"
            onChange={_handleInput}
          />
          <IonIcon icon={addOutline} color="light" size="large"></IonIcon>
        </div>
      )}
      {_images.length > 0 && (
        <div className={classes.Images}>
          {_images.map((item: any, index: number) => {
            return (
              <div
                className={classes.image}
                key={index}
                style={{ display: current !== index ? 'none' : 'block' }}
              >
                {editable && (
                  <Delete index={index} action={setImages} reset={setCurrent} />
                )}
                <img
                  alt="Head"
                  src={
                    typeof item === 'string'
                      ? (item as string)
                      : URL.createObjectURL(item as File)
                  }
                />
              </div>
            );
          })}
        </div>
      )}
      {_images.length > 0 && (
        <div className={classes.tiles}>
          {_images.map((item: any, index: number) => {
            return (
              <img
                alt="Head"
                key={index}
                src={
                  typeof item === 'string'
                    ? (item as string)
                    : URL.createObjectURL(item as File)
                }
                style={{
                  border: current !== index ? 'none' : '1.5px solid #A94064',
                }}
                onClick={() => {
                  setCurrent(index);
                }}
              />
            );
          })}
          {_images.length < 3 && (
            <div className={classes.add} onClick={handleAdd}>
              <IonIcon icon={addSharp} color="light" />
            </div>
          )}
          <div className={classes.overlay}></div>
        </div>
      )}
      {error.state && (
        <div
          className={classes.error}
          onClick={() => {
            setError({ state: false, message: '' });
          }}
        >
          {error.message}
        </div>
      )}
    </div>
  );
};

export default ImageCarouselInput;
