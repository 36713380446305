import jwt_decode from 'jwt-decode';
import { isLoggedIn } from './isLoggedIn';

export type DecodedToken = {
  exp: number;
  iat: string;
  role: string;
  id: number;
  stores?: { id: number; name: string; slug: string }[];
};
export type CurrentUser = {
  role: string;
  id: number;
  stores?: { id: number; name: string; slug: string }[];
};

export const getCurrentUser = () => {
  const token = localStorage.getItem('token');
  if (token) {
    if (isLoggedIn()) {
      const decode = jwt_decode<DecodedToken>(token);
      const { exp, iat, ...rest } = decode;
      return { ...rest };
    }
  }

  return {} as CurrentUser;
};
