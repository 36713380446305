import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { JoinRoutes, Socials } from '../../../../utils/user/routes';
import classes from './styles/mobile.module.scss';
import { mailOutline } from 'ionicons/icons';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';

const Mobile = () => {
  const [text, setText] =useState<string>('')
  return (
    <IonGrid className={classes.footer}>
      {/* <IonRow className={classes.linkGrp}>
        <h2>Join Our Society</h2>
        <IonList className={classes.list}>
          {JoinRoutes.map((item, index) => {
            return (
              <IonItem className={classes.item} key={index} lines="none">
                <Link to={item.route}>{item.name}</Link>
              </IonItem>
            );
          })}
        </IonList>
      </IonRow> */}
      <IonRow className={classes.info}>
        <IonCol size="12" className={classes.com}>
          <div className={classes.logo}>
            <IonIcon src={logo}></IonIcon>
            <span className={classes.span1}>Bazaar</span>{' '}
            <span className={classes.span2}>Cheap</span>
          </div>
          <p>
            Your ultimate destination for window shopping with a collection of
            products from top brands in all categories
          </p>
          <h2>Contact us</h2>

          <p>support@bazaarcheap.com</p>
        </IonCol>
        {/* <IonCol size="12" className={classes.newsletter}>
          <h2>Bazaar Cheap Newsletter</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
            dolorem natus asperiores at temporibus dolores.
          </p>
          <IonItem className={classes.input} lines="none">
            <IonInput
              value={text}
              color="primary"
              type="email"
              className={classes.field}
              onIonChange={(e) => setText(e.detail.value!)}
            >
              <IonIcon
                slot="start"
                icon={mailOutline}
                color="secondary"
                className={classes.icon}
              ></IonIcon>
            </IonInput>
            <IonButton
              color="secondary"
              type="submit"
              className={classes.btn}
              mode="ios"
              onClick={(e) => {
                // console.log(e)
              }}
            >
              Subscribe
            </IonButton>
          </IonItem>
        </IonCol> */}
        <IonCol size="12" className={classes.icons}>
          <div>
            <IonLabel slot="start" color="secondary" className={classes.head}>
              Follow Us On
            </IonLabel>
            <ul className={classes.list} slot="end">
              {Socials.map((item, index) => {
                return (
                  <IonButton
                    href={item.route}
                    target="_blank"
                    size="large"
                    fill="clear"
                    key={index}
                    className={classes.btn}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={item.icon}
                      color="light"
                      size="large"
                    />
                  </IonButton>
                );
              })}
            </ul>
          </div>
        </IonCol>
      </IonRow>
      <IonRow className={classes.Copyright}>
        Copyright &copy; Bazaar Cheap {new Date().getFullYear()} | All Rights
        Reserved
      </IonRow>
    </IonGrid>
  );
};

export default Mobile;
