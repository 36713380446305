import {
  IonAvatar,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { getDashboardRoutes } from '../../utils/routes';
import classes from './styles.module.scss';
import { GlobalContext } from '../../context/provider';
import { useContext, useMemo } from 'react';
import UserProfile from '../storeMenu/userProfile';
import { useHistory, useLocation } from 'react-router';
import { closeOutline } from 'ionicons/icons';
import logo from '../../Assets/logo/logo_clear_96x96.svg';
import { authService } from '../../utils/authService';

const Seller = (props: { user: any; action: any }) => {
  const { userState } = useContext<any>(GlobalContext);
  const location = useLocation();
  const history = useHistory();
  const userDetails = userState.getUser;

  const dashboardRoutes = useMemo(() => {
    if(location){
      return getDashboardRoutes({ location });
    }
    return []
  }, [location?.pathname]);

  return (
    <IonContent className={classes.seller}>
      <div className={classes.holder}>
        <div className={classes.header}>
          <div className={classes.logo}>
            <IonIcon src={logo}></IonIcon>
            <span className={classes.span1}>Bazaar</span>{' '}
            <span className={classes.span2}>Cheap</span>
          </div>
          <div
            className={classes.close + ' ion-activatable ripple-parent circle'}
            onClick={() => {
              props.action.close();
            }}
          >
            <IonRippleEffect></IonRippleEffect>
            <IonIcon icon={closeOutline} color={'secondary'}></IonIcon>
          </div>
        </div>
        <IonGrid className={classes.main}>
          <IonRow className={classes.userbar}>
            <IonCol>
              {!userDetails ? (
                <IonLoading
                  isOpen
                  message="loading..."
                  duration={1000}
                ></IonLoading>
              ) : (
                <>
                  <IonItem lines="none" className={classes.top} detail={false}>
                    <IonAvatar slot="start">
                      <UserProfile
                        color="#A94064"
                        placeholder={`${userDetails?.firstName?.[0]?.toUpperCase()} ${userDetails?.lastName?.[0]?.toUpperCase()}`}
                      />
                    </IonAvatar>
                    <IonLabel color="secondary">
                      {userDetails?.firstName.charAt(0).toUpperCase() +
                        userDetails?.firstName.slice(1)}{' '}
                      {userDetails?.lastName.charAt(0).toUpperCase() +
                        userDetails?.lastName.slice(1)}
                    </IonLabel>
                  </IonItem>
                </>
              )}
            </IonCol>
          </IonRow>
          {dashboardRoutes.map((item, index) => {
            return (
              !item.isAux &&
              item.name && (
                <IonRow
                  key={index}
                  // TODO: check logic validity
                  onClick={() => (item.name === '/login' ? authService.logoutUser() : null)}
                >
                  <IonCol size="12">
                    <div
                      className={classes.linkholder}
                      onClick={() => {
                        history.push({ pathname: item.path });
                        props.action.close();
                      }}
                    >
                      <IonIcon
                        icon={item.icon}
                        size="large"
                        slot="start"
                        color={
                          location.pathname === item.path
                            ? 'secondary '
                            : 'primary'
                        }
                      ></IonIcon>
                      <IonLabel
                        color={
                          location.pathname === item.path
                            ? 'secondary '
                            : 'primary'
                        }
                      >
                        {item.name}
                      </IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              )
            );
          })}
        </IonGrid>
        <IonGrid className={classes.aux}>
          {dashboardRoutes.map((item, index) => {
            return (
              item.isAux &&
              item.name && (
                <IonRow
                  key={index}
                  // TODO: check logic validity, use item.path
                  onClick={() => (item.path === '/login' ? authService.logoutUser() : null)}
                >
                  <IonCol size="12">
                    <div
                      className={classes.linkholder}
                      onClick={() => {
                        history.push({ pathname: item.path });
                        props.action.close();
                      }}
                    >
                      <IonIcon
                        icon={item.icon}
                        size="large"
                        slot="start"
                        color={
                          location.pathname === item.path
                            ? 'secondary '
                            : 'primary'
                        }
                      ></IonIcon>
                      <IonLabel
                        color={
                          location.pathname === item.path
                            ? 'secondary '
                            : 'primary'
                        }
                      >
                        {item.name}
                      </IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              )
            );
          })}
        </IonGrid>
      </div>
    </IonContent>
  );
};

export default Seller;
