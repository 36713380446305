import { IonRow, IonCol } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { SIGNUP, SIGNUP_ERROR } from '../../../context/actions/types';
import { signup } from '../../../context/actions/user';
import { GlobalContext } from '../../../context/provider';
import { DeviceContext } from '../../../deviceType';
import { SignupUser } from '../../../types/user';
import Btn from '../../../components/Btn';
import ContactCard from '../components/contactCard';
import InputCard from '../components/inputCard';
import AuthLayout from '../components/AuthLayout';
import classes from './style.module.scss';
import { eye } from 'ionicons/icons';
import { Link } from 'react-router-dom';

const registerFields = [
  {
    title: 'First Name',
    name: 'firstName',
    type: 'text',
  },
  {
    title: 'Last Name',
    name: 'lastName',
    type: 'text',
  },
  {
    title: 'Email',
    name: 'email',
    type: 'email',
  },
  {
    title: 'Phone Number',
    name: 'phoneNumber',
    type: 'tel',
    isPhoneNumber: true,
  },
  {
    title: 'Password',
    name: 'password',
    type: 'password',
    maxLength: 16,
    minLength: 8,
    icon: eye,
  },
  {
    title: 'Comfirm Password',
    type: 'password',
    name: 'comfirm',
    maxLength: 16,
    minLength: 8,
    icon: eye,
  },
];

interface Form extends SignupUser {
  comfirm: string;
  password: string;
}
type ComponentProps = {
  role?: string;
};

const SignUp: React.FC<ComponentProps> = ({ role = 'user' }) => {
  const device = useContext(DeviceContext);
  const history = useHistory();
  const [form, setForm] = useState<Form>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    role,
    password: '',
    comfirm: '',
  });
  const { userDispatch, userState } = useContext<any>(GlobalContext);
  const [match, setMatch] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ state: boolean; message: string }>();

  // comfirm password match Logic
  useEffect(() => {
    if (
      form?.comfirm !== undefined &&
      form?.password !== undefined &&
      form?.comfirm !== '' &&
      form?.password !== ''
    ) {
      form?.comfirm !== form?.password ? setMatch(false) : setMatch(true);
    } else {
      setMatch(undefined);
    }
  }, [form?.comfirm, form?.password]);

  useEffect(() => {
    if (userState?.signup?.id) {
      resetForm();
      userDispatch({ type: SIGNUP, payload: {} });
      history.push('/login');
    }
    if (userState?.signupError) {
      setError({
        state: true,
        message: userState?.signupError!?.response.data!?.message
          ? userState?.signupError!?.response.data.message
          : userState?.signupError!?.message,
      });
      userDispatch({ type: SIGNUP_ERROR, payload: null }); // this clears the signup api error
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState?.signup?.email, userState?.signupError]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const user: SignupUser = {
      firstName: form!?.firstName,
      lastName: form!?.lastName,
      role,
      email: form!?.email,
      password: form!?.password,
      phoneNumber: form!?.phoneNumber,
    };
    if (user.phoneNumber?.trim()?.length === 0 || user.phoneNumber === '256' ) {
      delete user.phoneNumber;
    }
    await signup(user)(userDispatch);
    setLoading(false);
  };
  const resetForm = () => {
    setForm({
      firstName: '',
      lastName: '',
      role,
      email: '',
      phoneNumber: '',
      password: '',
      comfirm: '',
    });
  };

  return (
    <AuthLayout>
      <div className={classes['signup-page']+' w-full md:w-[70%] mt-3 mx-auto'}>
        <div className={classes['title-wrapper']}>
          <h1>
            {role === 'seller' ? 'Create Vendor Account' : 'Create Account'}
          </h1>
        </div>

        <div className={classes['form-wrapper']}>
          <form onSubmit={handleSubmit}>
            <IonRow>
              {registerFields.map((item, index) => (
                <IonCol
                  size={device?.width < 600 ? '12' : '6'}
                  key={index}
                  className={classes['field-col']}
                >
                  {item.isPhoneNumber ? (
                    <ContactCard
                      title="Phone Number"
                      value={setForm}
                      name={'phoneNumber'}
                    />
                  ) : (
                    <InputCard
                      title={item.title}
                      setForm={setForm}
                      name={item.name}
                      type={item.type}
                      maxLength={item.maxLength}
                      minLength={item.minLength}
                      icon={item.icon}
                    />
                  )}
                </IonCol>
              ))}
            </IonRow>

            {error?.state && (
              <IonRow className={classes.error}>
                <IonCol size="12">
                  <div>{error.message} Please try again later.</div>
                </IonCol>
              </IonRow>
            )}

            <IonRow className={classes['submit-btn-row']}>
              <IonCol size="12">
                <Btn
                  onClick={(e) => e}
                  match={match}
                  name="Register"
                  loading={loading}
                />
              </IonCol>
            </IonRow>

            <IonRow className={classes['login-row']}>
              <IonCol size="12">
                <p>
                  Already have an account? <Link to="login">Login</Link>
                </p>
              </IonCol>
            </IonRow>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUp;
