import { IonCol, IonGrid, IonList, IonRow } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import ProductBlock from '../../../components/cards/Product_Block';
import Layout from '../../../components/Layout/user/Layout';
import { getTopCategories } from '../../../context/actions/top_categories';
import { GlobalContext } from '../../../context/provider';
import { ProductType } from '../../../types/product';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';

const CategoryProducts: React.FC = (props: any) => {
  const size = useContext(DeviceContext);
  const { topCategoriesState, topCategoriesDispatch } =
    useContext<any>(GlobalContext);
  const [products, setProducts] = useState<ProductType[]>([]);
  const { type } = props.location && props.location.state;

  useEffect(() => {
    getProductData();
  }, []);

  useEffect(() => {
    if (topCategoriesState?.topCategories) {
      const categories = topCategoriesState?.topCategories;
      const categoryProduct = categories.find((category: any) => {
        return category.type.type === type;
      });
      const newProducts = categoryProduct.products;
      setProducts(newProducts);
    }
  }, []);

  const getProductData = async () => {
    await getTopCategories()(topCategoriesDispatch);
  };

  return (
    <Layout>
      <section className={classes.desktop}>
        <h1 className={classes.type}>{type}</h1>
        <IonGrid className={classes.grid} fixed={true}>
          <IonRow>
            <IonCol className={classes.flex}>
              <div
              className={'w-full grid grid-cols-2 md:grid-cols-6 gap-2'}
              >
                {products?.map((item: any, index: number) => (
                    <ProductBlock data={item} />
                ))}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </section>
    </Layout>
  );
};

export default CategoryProducts;
