import {
  IonCard,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useState } from 'react';
import classes from './styles.module.scss';
import { codes as countryCodes } from '../../../utils/countryCodes';

const ContactCard = (props: {
  title: string;
  name: string;
  value: (arg0: (form: any) => any) => void;
}) => {
  const uganda = countryCodes.find((code) => code.name === 'Uganda');
  const [selectedCountry, setSelectedCountry] = useState(uganda);
  return (
    <IonCard className={classes.countryCard}>
      <IonCardContent>
        <IonItem lines="none">
          <IonLabel>{`${selectedCountry?.code} ${selectedCountry?.dial_code}`}</IonLabel>
          <IonSelect
            value={selectedCountry?.dial_code}
            onIonChange={(e) => {
              setSelectedCountry(
                countryCodes.find(
                  (country) => country.dial_code === e.detail.value
                )
              );
            }}
          >
            {countryCodes.map((country) => (
              <IonSelectOption key={country.name} value={country.dial_code}>
                {`${country.code} ${country.name} (${country.dial_code})`}
              </IonSelectOption>
            ))}
          </IonSelect>
          <IonInput
            type="tel"
            placeholder={props.title}
            name={props.name}
            color="primary"
            maxlength={9}
            onIonChange={(e) => {
              const number = `${selectedCountry?.dial_code}${e.detail.value}`.replace(/^\+/, '')
              props.value((form: any) => ({
                ...form,
                [props.name]: number,
              }));
            }}
          ></IonInput>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default ContactCard;
