import { IonGrid } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getCurrentUser } from '../../../utils/getClientInfo';
import WishlistIcon from '../../../pages/user/wish-list/wish-list-icon';
import { ProductType } from '../../../types/product';
import ImageCarousel from '../../ImageCarousel';
import { getProductStatus } from '../../../utils/products';
import classNames from 'classnames';

type ProductBlockProps = {
  data: ProductType;
  customStyles?: { wrapper?: string };
  isVendorView?: boolean;
};
const ProductBlock = ({
  data,
  customStyles = {},
  isVendorView,
}: ProductBlockProps) => {
  const { images, id, name, price, store } = data;
  const history = useHistory();
  const client = getCurrentUser();
  const [path, setPath] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    if (client.role === 'seller' && location.pathname.includes('dashboard')) {
      setPath(`/dashboard/items/detail?q=${id}&i=${name.slice(0, 30)}`);
    } else {
      setPath(`/${store?.name}/product/${name.slice(0, 30)}?id=${id}`);
    }
  }, []);

  const status = useMemo(() => {
    return getProductStatus(data.status || '');
  }, [data?.status]);


  const statusBadge = classNames(
    'px-3 py-[2px]  text-xs  rounded-md capitalize font-semibold',
    {
      'bg-orange-200 text-orange-500': status === 'pending',
      'bg-gray-200 text-gray-500': status === 'draft',
      'bg-red-200 text-red-500': status === 'rejected',
      'bg-green-200 text-green-500': status === 'published',

    }
  );

  return (
    <div
      className={`relative flex flex-col w-full  h-[200px] md:h-[320px]  shadow-xs bg-white ${customStyles.wrapper}`}
      onClick={() => {
        history.push({
          pathname: path,
          state: { id },
        });
      }}
    >
      <div className="relative w-full h-[70%] md:h-[80%]">
        <ImageCarousel data={images} name={data.name} />

        {!isVendorView && (
          <div className="absolute  items-center bottom-2 right-2 z-50 cursor-pointer ">
            <WishlistIcon productId={data.id}  />
          </div>
        )}
        {!isVendorView && (
          <div className="absolute italic rounded-sm text-xs text-white p-1 capitalize font-bold items-center top-0 left-0 z-50 cursor-pointer bg-secondary ">
            {store?.name}
          </div>
        )}
      </div>
      <IonGrid className="m-0 px-2 py-2 text-black">
        <p className="text-base w-[90%] font-normal capitalize whitespace-nowrap overflow-hidden text-ellipsis ">
          {data.name}
        </p>
        <div className="w-full text-md font-semibold h-auto ">
          UGX{' '}
          {price.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}
        </div>
        {isVendorView && (
          <div className="flex w-full mt-1 ">
            <span className={statusBadge}>{status}</span>
          </div>
        )}
      </IonGrid>
    </div>
  );
};

export default ProductBlock;
