import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonPopover,
} from '@ionic/react';
import {
  logOutSharp,
  settingsSharp,
  swapHorizontal,
  personOutline,
  logOutOutline,
  bagAddOutline,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { User } from '../../types/user';
import { authService } from '../../utils/authService';
import { useMemo, useState } from 'react';
import { CurrentUser, getCurrentUser } from '../../utils/getClientInfo';
import classes from './styles.module.scss';
import UserProfile from './userProfile';
import BecomeASellerModal from '../becomeSellerModal';

const Menu = (props: {
  open: boolean;
  close: (e: any) => void;
  userDetails: User;
  openEvent: any;
}) => {
  const location = useLocation();
  const user = getCurrentUser();
  const isOnDashBoard = location.pathname.includes('dashboard');
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const sellerHasStore: boolean =
    user?.role === 'seller' && !!user?.stores?.length;

  const handleSellerMenuItemClick = (item: MenuItem) => {
    if (item.route === '/logout') {
      authService.logoutUser();
    } else if (item.isSwitch) {
      if (!sellerHasStore) {
        history.push('/dashboard/create-store');
      } else {
        history.push(isOnDashBoard ? item.route : '/dashboard');
      }
    } else {
      history.push(item.route);
    }
  };

  const handleUserMenuItemClick = async (item: object | any) => {
    if (item.route === '/become-vendor') {
      setIsModalOpen(true);
    } else if (item.route === '/logout') {
      authService.logoutUser();
    } else {
      history.push(item.route);
    }
  };

  const sellerMenu = useMemo(() => {
    return getSellerMenuItems({ location, user });
  }, [location.pathname, user?.role, user?.stores?.length]);

  const getInitials = (): string => {
    const first = props.userDetails?.firstName[0].toUpperCase();
    const last = props.userDetails?.lastName[0].toUpperCase();
    return first + last;
  };

  return (
    <IonPopover
      className={classes.menu}
      trigger="store-menu-trigger"
      triggerAction="click"
      reference="trigger"
    >
      <IonContent>
        {/* Become vendor modal */}
        <BecomeASellerModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />

        <div className={classes.container}>
          <IonItem
            lines="full"
            className={classes.top}
            href="/#"
            detail={false}
          >
            <IonAvatar slot="start">
              <UserProfile color="#A94064" placeholder={getInitials()} />
            </IonAvatar>
            <IonLabel color="secondary">
              {props.userDetails?.firstName.charAt(0).toUpperCase() +
                props.userDetails?.firstName.slice(1)}{' '}
              {props.userDetails?.lastName.charAt(0).toUpperCase() +
                props.userDetails?.lastName.slice(1)}
            </IonLabel>
          </IonItem>
          <IonItemGroup>
            {user?.role === 'seller' &&
              sellerMenu?.map((item: any, index: any) => {
                return (
                  !item.hidden && (
                    <IonItem
                      onClick={() => handleSellerMenuItemClick(item)}
                      key={index}
                      lines={item.route !== '/logout' ? 'full' : 'none'}
                      detail={false}
                      button
                    >
                      <IonIcon
                        slot="start"
                        icon={item.icon}
                        color="primary"
                        size="default"
                      ></IonIcon>
                      <IonLabel>{item.name}</IonLabel>
                    </IonItem>
                  )
                );
              })}
            {user.role === 'user' &&
              userMenu.map((item: any, index: any) => (
                <IonItem
                  onClick={() => handleUserMenuItemClick(item)}
                  key={index}
                  lines={item.route !== '/logout' ? 'full' : 'none'}
                  detail={false}
                  button
                >
                  <IonIcon
                    slot="start"
                    icon={item.icon}
                    color="primary"
                    size="default"
                  ></IonIcon>
                  <IonLabel>{item.name}</IonLabel>
                </IonItem>
              ))}
          </IonItemGroup>
        </div>
      </IonContent>
    </IonPopover>
  );
};

export default Menu;

export type MenuItem = {
  route: string;
  icon?: any;
  name: string;
  isSwitch?: boolean;
  disabled?: boolean;
  hidden?: boolean;
};

export type CurrentLocation = {
  pathname: string;
};
export const getSellerMenuItems = ({
  location,
  user,
}: {
  location: CurrentLocation;
  user: CurrentUser;
}): MenuItem[] => {
  const isOnDashBoard = location.pathname.includes('dashboard');
  const sellerHasStore: boolean =
    user?.role === 'seller' && !!user?.stores?.length;

  return [
    {
      name: 'Create Shop',
      route: '/dashboard/create-store',
      icon: bagAddOutline,
      hidden: sellerHasStore,
    },
    {
      name: 'Switch to Shopping' ,
      route: '/stores' ,
      icon: swapHorizontal,
      hidden: !isOnDashBoard,
    },
    {
      name:  'Switch to Dashboard',
      route: '/dashboard/home',
      icon: swapHorizontal,
      hidden: isOnDashBoard || !sellerHasStore,
    },
    // { name: 'Add Item', route: '/dashboard/create-product', icon: addSharp },
    // { name: 'Favourites', route: '/wishlist', icon: listSharp },
    // { name: 'Notifications', route: '#', icon: notificationsSharp }, // remove before push
    // { name: 'Settings', route: '/dashboard/settings', icon: settingsSharp },
    { name: 'Log out', route: '/logout', icon: logOutSharp },
  ];
};

export const userMenu: MenuItem[] = [
  { name: 'Account', route: '/account', icon: personOutline },
  { name: 'Become a Vendor', route: '/become-vendor', icon: swapHorizontal },
  // { name: 'Entry', route: '/#', icon: eyeSharp },
  // { name: 'Notifications', route: '/#', icon: notificationsSharp },
  // { name: 'Settings', route: '/#', icon: settingsOutline },
  { name: 'Log out', route: '/logout', icon: logOutOutline },
];
